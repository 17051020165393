.batch-plan-duplicate-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.batch-plan-duplicate-modal {
    background: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.batch-plan-duplicate-modal h2 {
    margin: 0 0 16px 0;
    font-size: 20px;
    color: var(--text-color);
}

.batch-plan-duplicate-list {
    margin: 0 0 24px 0;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.batch-plan-duplicate-list::-webkit-scrollbar {
    width: 6px;
}

.batch-plan-duplicate-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.batch-plan-duplicate-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.batch-plan-duplicate-item {
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    margin: 8px 0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.batch-plan-duplicate-item:hover {
    background-color: var(--background-color-hover);
}

.batch-plan-duplicate-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.batch-plan-duplicate-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.batch-plan-duplicate-button.cancel {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.batch-plan-duplicate-button.cancel:hover {
    background-color: var(--background-color-hover);
}

@media (max-width: 480px) {
    .batch-plan-duplicate-modal {
        padding: 20px;
        width: calc(100% - 32px);
        margin: 16px;
    }

    .batch-plan-duplicate-buttons {
        flex-direction: column;
    }

    .batch-plan-duplicate-button {
        width: 100%;
        padding: 12px;
        text-align: center;
    }
} 