.plan-selection-container {
    min-height: 100vh;
    /* padding: 1rem; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.plan-selection-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.plan-selection-container h1 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: var(--ml-color-moonlite-black);
    font-weight: 600;
}

.plan-selection-header {
    text-align: center;
    margin-bottom: 14px;
}

.plan-selection-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    margin-top: 0;
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
}

.plan-selection-subtitle {
    font-size: 1.1rem;
    color: var(--text-secondary);
    max-width: 600px;
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn 1s ease-out 0.4s forwards;
}

.plan-selection-billing-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0;
    opacity: 0;
    animation: fadeIn 1s ease-out 0.8s forwards;
}

.plan-selection-billing-option {
    font-size: 1rem;
    color: var(--ml-color-dark);
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    position: relative;
    transition: background-color 0.2s;
    border: none;
    background-color: var(--primary-light);
}

.plan-selection-billing-option.active {
    color: white;
    font-weight: 500;
    background-color: var(--ml-color-dark);
}

.plan-selection-billing-option span {
    font-size: 12px;
    position: absolute;
    top: calc(100%);
    left: 0;
    right: 0;
    color: var(--ml-color-dark);
    text-align: center;
}

.plan-selection-annual-savings {
    text-align: center;
    color: var(--success);
    font-size: 0.9rem;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
}

.plan-selection-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: 1rem auto;
    max-width: 900px;
    margin-top: 24px;
}

.plan-selection-plan {
    background: var(--background);
    border: 2px solid var(--border-color);
    border-radius: 1rem;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: fadeInUp 1s ease-out 1s forwards;
    will-change: transform, opacity;
}

.plan-selection-plan:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.plan-selection-plan-header {
    text-align: left;
    padding: 1.5rem;
    padding-bottom: 0;
}

.plan-selection-plan-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.plan-selection-plan-price {
    padding: 0 1.5rem;
}

.plan-selection-price {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
}

.plan-selection-price-text span {
    font-size: 14px;
}

.plan-selection-plan-description {
    font-size: 14px;
    line-height: 1.8;
    color: var(--text-secondary);
    text-align: left;
    margin-top: 14px;
}

.plan-selection-plan-features {
    list-style: none;
    padding: 1.5rem;
    margin: 0;
    flex-grow: 1;
}

.plan-selection-plan-features li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    color: var(--text-color);
    font-size: 0.95rem;
}

.plan-selection-plan-features li:last-child {
    margin-bottom: 0;
}

.plan-selection-feature-check {
    color: var(--primary);
    flex-shrink: 0;
}

.plan-selection-plan-footer {
    padding: 1.5rem;
}

.plan-selection-select-button {
    width: 100%;
    padding: 1rem;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: 1px solid var(--ml-color-darkest);
    background-color: var(--ml-color-darkest);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
}

.plan-selection-select-button.plan-selection-select-button-inverted {
    border: 1px solid var(--ml-color-darkest);
    color: var(--ml-color-darkest);
    background-color: white;
    cursor: default;
}

.plan-selection-select-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.plan-selection-select-button.selected {
    background-color: var(--success);
}

.plan-card {
    display: flex;
    flex-direction: column;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    padding: 1.5rem;
    text-align: left;
    transition: all 0.3s ease;
    cursor: pointer;
    background: white;
}

.plan-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.plan-card.selected {
    border-color: var(--ml-color-accent);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.1);
}

.select-plan-btn {
    width: 100%;
    padding: 0.75rem;
    background: var(--ml-color-accent);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.select-plan-btn:hover {
    background: var(--ml-color-accent-dark);
}

.select-plan-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.plan-selection-error {
    text-align: center;
    margin: 2rem 0;
    padding: 1rem;
    background-color: var(--error-light);
    border: 1px solid var(--error);
    border-radius: 0.5rem;
}

.plan-selection-error p {
    color: var(--error);
    margin: 0;
    font-size: 1rem;
}

.plan-selection-subscribe {
    text-align: center;
    margin: 2rem 0;
}

.plan-selection-subscribe-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-on-primary);
    background-color: var(--primary);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 200px;
}

.plan-selection-subscribe-button:hover:not(:disabled) {
    background-color: var(--primary-dark);
    transform: translateY(-1px);
}

.plan-selection-subscribe-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.plan-selection-subscribe-button .loading-symbol {
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: var(--text-on-primary);
    border-right-color: transparent;
}

@media (min-width: 768px) {
    .plan-selection-container {
        /* padding: 2rem; */
    }

    .plan-selection-content {
        padding: 2rem;
        padding-top: 0;
    }

    .plan-selection-container h1 {
        font-size: 2.5rem;
    }

    .plan-selection-subtitle {
        font-size: 1.1rem;
    }

    .billing-period-btn {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
    }

    .plan-selection-billing-toggle {
        gap: 1rem;
    }

    .plan-card {
        padding: 2rem;
    }

    .plan-name {
        font-size: 1.8rem;
    }

    .price {
        font-size: 2.5rem;
    }

    .plan-features li {
        font-size: 1rem;
        padding: 0.75rem 0;
    }

    .select-plan-btn {
        padding: 1rem;
        font-size: 1.1rem;
    }
}

@media (min-width: 1024px) {
    .plan-selection-cards {
        flex-direction: row;
        gap: 2rem;
    }

    .plan-card {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .plan-selection-container {
        /* padding: 1rem; */
    }

    .plan-selection-title {
        font-size: 2rem;
    }

    .plan-selection-cards {
        grid-template-columns: 1fr;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(12px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Ensure animations only play on initial page load */
.no-animation {
    animation: none !important;
    opacity: 1 !important;
    transform: none !important;
}

/* Add smooth transitions for hover effects */
.plan-selection-plan {
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.plan-selection-plan:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
} 