.stage-filter-dropdown {
    position: relative;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
    margin-right: 10px;
}

/* Dropdown button */
.stage-filter-button {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    min-width: 180px;
    font-size: 14px;
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.stage-filter-button:hover {
    border-color: #c0c0c0;
}

.selected-count {
    background-color: #f5f5f5;
    padding: 2px 6px;
    border-radius: 3px;
    margin: 0 8px;
    font-size: 12px;
    color: #333;
    font-weight: 500;
}

.dropdown-arrow {
    margin-left: auto;
    font-size: 10px;
    color: #666;
    margin-right: 5px;
}

.clear-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0 4px;
    font-size: 12px;
    line-height: 1;
    border-radius: 50%;
}

.clear-button:hover {
    color: #333;
    background-color: #f5f5f5;
}

/* Dropdown panel */
.stage-filter-panel {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 220px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
}

.filter-option {
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.15s;
}

.filter-option:hover {
    background-color: #f9f9f9;
}

.filter-option:last-child {
    border-bottom: none;
}

.filter-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.filter-checkbox {
    margin-right: 10px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.stage-tag {
    flex-grow: 1;
    font-weight: 500;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 0;
    padding-left: 14px;
}

.stage-tag.new {
    background-color: var(--ml-color-stage-new);
}

.stage-tag.pouring {
    background-color: var(--ml-color-stage-pouring);
}

.stage-tag.curing {
    background-color: var(--ml-color-stage-curing);
}

.stage-tag.testing {
    background-color: var(--ml-color-stage-testing);
}

.stage-tag.done {
    background-color: var(--ml-color-stage-done);
}

.stage-count {
    background-color: #f5f5f5;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 12px;
    min-width: 20px;
    text-align: center;
    color: #666;
}

.select-all {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #e0e0e0;
}

.select-all-button {
    border: none;
    background: none;
    color: #0066cc;
    cursor: pointer;
    font-size: 13px;
    padding: 4px 8px;
    font-weight: 500;
}

.select-all-button:hover {
    text-decoration: underline;
} 