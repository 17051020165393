html {
    scroll-behavior: smooth;
}

body {
    --ml-font-styles: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --ml-font-size: 16px;
    --ml-color-darkest: #252323;
    --ml-color-dark: #70798C;
    --ml-color-lightest: #f5f1ED;
    --ml-color-lighter: #dac7bc;
    --ml-color-light: #A99985;
    --ml-color-button: #F9E7E7;
    --ml-color-card: #EBADAD;
    --ml-color-card-batches: #F4F0FA;
    --ml-color-card-candles: #E8EFE6;
    --ml-color-acc: #ffbf42;
    --ml-color-accent: #334e87;
    --ml-color-val-green: #0f860b;
    --ml-color-val-red: #860b0b;
    --ml-color-val-yellow: #ffef1585;
    --ml-color-confirmation: rgb(31, 76, 202);
    --ml-color-moonlite-black: #212121;
    --ml-color-empty-state-background: rgb(206, 206, 206);
    --ml-color-borders: rgb(228, 228, 228);
    --ml-color-border-dark: rgb(214, 214, 214);
    
    /* CureTimerModal variables */
    --text-color: var(--ml-color-darkest);
    --background-color: #ffffff;
    --background-color-hover: #f5f5f5;
    --border-color: var(--ml-color-borders);
    --primary-color: var(--ml-color-accent);
    
    font-family: var(--ml-font-styles);
}

p {
    margin: 0;
    font-size: var(--ml-font-size);
}

button {
    font-family: var(--ml-font-styles);
}

input[type=date] {
    font-family: var(--ml-font-styles);
}

.moonlite-main {
    margin: 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.header-container {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    min-height: 64px;
}

.header-logo-main {
    font-weight: 900;
    font-size: 20px;
    color: var(--ml-color-darkest);
    cursor: pointer;
}

.header-logo-main.header-logo-icon svg {
    max-height: 40px;
    width: 80px;
}

.header-logo-main svg {
    width: 180px;
}

.header-logo-main span {
    font-weight: 400;
    color: var(--ml-color-darkest);
}

.header-profile-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--ml-font-size);
    gap: 12px;
    z-index: 2;
}

.header-profile-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: 180px;
    background-color: white;
    border: 1px solid var(--ml-color-borders);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header-profile-picture {
    max-width: 32px;
    height: 32px;
    border-radius: 20px;
    overflow: hidden;
}

.header-profile-picture img {
    width: 100%;
}

.header-profile-menu span {
    padding: 12px;
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
}

.header-profile-menu span:hover {
    background-color: #f2f2f2;
}

.header-return-to-page {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 12px;
}

.header-return-to-page div {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.header-navigation-container {
    display: none;
    gap: 36px;
    align-items: center;
}

.header-navigation-container span.header-navigation-bar-selected {
    color: rgb(0, 0, 0);
}

.header-navigation-container span {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 48px;
    cursor: pointer;
    color: rgb(148, 148, 148);
}

.video-wrapper {
    margin-top: 48px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-beta-badge {
    font-size: 10px;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
    background-color: var(--ml-color-moonlite-black);
    padding: 4px 12px;
    border-radius: 16px;
    font-weight: 700;
}

.login-container {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.login-hero-image-container {
    margin-top: 20px;
    --ml-login-image-height: 540px;
    height: var(--ml-login-image-height);
    max-height: var(--ml-login-image-height);
}

.login-hero-image-desktop-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.login-hero-image-desktop-container img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.login-hero-image-container img {
    height: 100%;
}

.login-hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 14px;
    padding: 0px 12px;
}

.header-unauth-heading-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0px;
}

.header-unauth-login-button {
    cursor: pointer;
    font-weight: 500;
}

.login-hero-text-container h2 {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
}

.login-hero-text-container p {
    margin: 0;
    margin-top: 0px;
    font-size: 18px;
    color: rgb(79, 79, 79);
}

.login-button-google {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    box-sizing: border-box;
    min-height: 54px;
    background-color: var(--ml-color-darkest);
    border: 1px solid var(--ml-color-darkest);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-size: 16px;
    margin-top: 40px;
}

.login-button-google-coming-soon {
    background-color: transparent;
    color: var(--ml-color-darkest);
    cursor: default;
}

.home-create-new-recipe-button {
    margin-left: 12px;
    box-sizing: border-box;
    background-color: var(--ml-color-accent);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-size: 14px;
    padding: 4px 14px;
    margin-top: 8px;
}

.login-sections {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 120px;
}

.login-section {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px 16px;
}

.login-section-dark {
    background-color: #16365f16;
    padding: 14px;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.login-section-image {
    width: 80%;
    max-width: 80%;
    margin: 24px auto;
    display: flex;
    justify-content: center;
}

.login-section-image img {
    width: 100%;
    border: 1px solid rgb(187, 187, 187);
    padding: 14px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.login-section h3 {
    font-size: 24px;
    margin: 0;
    text-align: center;
    font-weight: 600;
}

.login-section-badge {
    align-self: center;
    font-size: 13px;
    padding: 4px 12px;
    background-color: #16365f16;
    border-radius: 12px;
    margin-bottom: 8px;
}

.login-section p {
    font-size: 16px;
    line-height: 1.8;
    margin: 0px;
    color: var(--ml-color-dark);
    text-align: left;
    margin-bottom: 14px;
}

.login-long-form-info {
    padding: 0px 16px;
}

.login-long-form-info h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--ml-color-dark);
    margin-bottom: 14px;
}

.login-long-form-info p strong {
    color: black;
}

.login-long-form-info p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: grey;
}

.login-long-logo {
    width: 40px;
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
}

.login-pricing-card {
    width: 100%;
    max-width: 400px;
    margin-top: 48px;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 14px 20px;
    box-sizing: border-box;
}

.login-pricing-card h4 {
    font-size: 48px;
    margin: 12px 0px;
    font-weight: 600;
    text-align: center;
}

.login-pricing-card-subtitle {
    text-align: center;
    margin-top: 14px;
    color: rgb(79, 79, 79);
}

.login-faq-question {
    margin-top: 36px;
}

.login-faq-question p:first-of-type {
    font-weight: 600;
    margin-bottom: 8px;
    color: black;
}

.login-faq-question p:last-of-type {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    color: rgb(90, 90, 90);
}

.login-sections-desktop {
    margin-top: 120px;
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.login-section-desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 36px;
    margin-bottom: 120px;
}

.login-section-desktop-reverse {
    flex-direction: row-reverse;
}

.login-section-desktop h2 {
    font-weight: 500;
    font-size: 32px;
    margin: 0;
    margin-bottom: 20px;
}

.login-section-additionals {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.login-section .login-section-additional-cards {
    justify-content: center;
}

.login-section-desktop p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--ml-color-dark);
    line-height: 1.8;
    margin-bottom: 20px;
}

.login-section-desktop button,
.login-section button {
    background-color: white;
    border: 1px solid var(--ml-color-moonlite-black);
    color: var(--ml-color-moonlite-black);
    font-size: 16px;
    font-weight: 600;
    padding: 12px 32px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.login-section button {
    font-size: 14px;
}

.login-section-desktop button:hover {
    background-color: var(--ml-color-moonlite-black);
    color: white;
}

.login-section-desktop-ft-text {
    flex-basis: 30%;
}

.login-section-desktop-ft-img {
    flex-basis: 65%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
}

.login-section-desktop-ft-img:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.28) 0px 15px 12px;
}

.login-section-desktop-ft-img-backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--ml-color-dark);
    z-index: -1;
    border-radius: 4px;
    transform: scale(1.02) rotate(1deg);
    opacity: 0.4;
}

.login-section-desktop-ft-img img {
    width: 100%;
}

.login-section-desktop-testimonials {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
}

.login-section-mobile-testimonials {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.login-testimonial-desktop {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
}

.login-testimonial-desktop.login-testimonial-mobile {
    width: 100%;
    text-align: left;
}

.login-testimonial-desktop p {
    margin: 0;
    font-size: 20px;
    text-align: left;
}

.login-testimonial-desktop.login-testimonial-mobile p {
    font-size: 16px;
}

.login-testimonial-desktop span {
    font-size: 14px;
}

.login-testimonial-desktop.login-testimonial-mobile span {
    color: rgb(94, 94, 94);
}

.login-section-additional-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 80px;
}

.login-section .login-section-additional-cards {
    margin-top: 40px;
}

.login-section .login-section-additional-card {
    width: 120px;
    min-width: 120px;
    margin-bottom: 16px;
}

.login-section .login-section-additional-card svg {
    font-size: 28px;
}

.login-section .login-section-additional-card p {
    font-size: 14px;
}

.login-section-additional-card {
    width: 240px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 32px;
}

.login-section-additional-card svg {
    font-size: 32px;
    color: rgb(73, 73, 73);
}

.login-section-additional-card p {
    font-size: 16px;
    text-align: center;
    color: rgb(73, 73, 73);
}

.login-footer {
    border-top: 1px solid var(--ml-color-moonlite-black);
    padding-top: 48px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: var(--ml-font-size);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.responsive-navigation-bar {
    position: relative;
}

.navigation-bar-container {
    margin: 0px auto;
    display: flex;
    gap: 24px;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* border-top: 1px solid rgb(214, 214, 214); */
    /* background-color: white; */
}

.navigation-bar-floating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    border: 1px solid var(--ml-color-borders);
    background-color: white;
    margin-bottom: 16px;
    border-radius: 24px;
    padding: 4px 16px;
    box-shadow: rgba(0, 0, 0, 0.081) 0px 3px 6px, rgba(0, 0, 0, 0.081) 0px 3px 6px;
}

.navigation-bar-container span {
    font-size: var(--ml-font-size);
    color: grey;
    padding: 12px 8px;
    font-weight: 500;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 4px;
}

.navigation-bar-container span svg {
    transition: all 0.4s;
    font-size: 24px;
}

span.navigation-bar-selected {
    color: var(--ml-color-accent);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

span.navigation-bar-selected svg {
    transform: rotate(15deg);
}

.navigation-bar-container span div {
    font-size: 10px;
}

.nav-internal-sections {
    max-width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: 12px;
    margin-bottom: 14px;
    gap: 16px;
    box-sizing: border-box;
}

.nav-internal-sections span {
    font-size: 16px;
    font-weight: 500;
    /* letter-spacing: 1px; */
    text-transform: capitalize;
    /* border-bottom: 2px solid var(--ml-color-borders); */
    /* padding: 8px 12px; */
    color: var(--ml-color-dark);
    cursor: pointer;
}

span.nav-internal-section-selected {
    font-weight: 500;
    color: var(--ml-color-darkest)
    /* border-bottom: 2px solid var(--ml-color-accent); */
}

.notes-container {
    font-size: var(--ml-font-size);
    white-space: pre-wrap;
}

.notes-container p {
    font-size: var(--ml-font-size);
    color: rgb(41, 41, 41) !important;
    white-space: pre-wrap;
    margin: 0 !important;
}

.notes-container-edit-description-button {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
}

.notes-container-edit-description-button span {
    cursor: pointer;
    color: grey;
    font-size: 14px;
    text-decoration: underline;
}

.home-container {
    margin-top: 40px;
    margin-left: 14px;
    margin-right: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    font-size: var(--ml-font-size);
    color: var(--ml-color-dark);
}

.home-container h2 {
    text-align: center;
    margin: 0;
    font-weight: 400;
}

.home-featured-section h2 {
    text-align: left;
    margin: 0;
    font-weight: 400;
    margin-bottom: 24px;
}

.home-featured-section {
    margin-bottom: 80px;
}

.home-container p {
    margin-bottom: 12px;
    line-height: 1.5;
}

.home-authenticated-desktop {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.home-authenticated-desktop-main {
    flex-grow: 1;
}

.home-authenticated-desktop-side {
    width: 500px;
    min-width: 500px;
}

.home-authenticated-desktop-header h3 {
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    color: var(--ml-color-moonlite-black);
}

.home-authenticated-desktop-header p {
    color: rgb(155, 155, 155);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.home-featured-items {
    display: flex;
    justify-content: space-between;
}

.home-auth-featured-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: stretch;
}

.home-auth-featured-section {
    border: 1px solid rgba(0, 0, 0, 0.208);
    padding: 14px 32px;
}

.home-authenticated-desktop-side .home-auth-featured-section {
    border: 1px solid transparent;
    background-color: #859aa912;
}

.home-auth-featured-section h4 {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    color: var(--ml-color-moonlite-black);
}

.home-auth-supplies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
}

.home-auth-supply-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-basis: 50%;
    margin-top: 12px;
}

.home-auth-supply-item h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.home-auth-supply-item p {
    font-size: 14px;
    font-weight: 500;
    color: rgb(160, 160, 160);
}

.home-auth-supply-item-symbol {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #86420b18;
}

.home-auth-supply-item-symbol svg {
    font-size: 32px;
}

.home-auth-summaries {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-auth-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 400;
}

.home-auth-summary-title {
    font-weight: 500;
}

.home-auth-featured-item {
    padding: 6px 0px;
    font-weight: 400;
    cursor: pointer;
}

.home-auth-featured-item:hover, 
.home-auth-button-more span:hover {
text-decoration: underline;
}

.home-auth-button-more {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}

.home-auth-button-more span {
    font-weight: 500;
    cursor: pointer;
}


.home-featured-item {
    width: 30%;
    cursor: pointer;
    padding: 12px;
    box-sizing: border-box;
    background-color: rgba(228, 237, 249, 0.665);
    border-radius: 8px;
    color: rgb(80, 80, 80);
    border: 1px solid var(--ml-color-borders);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home-featured-recent-items {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: rgba(228, 237, 249, 0.665);
    border-radius: 12px;
    padding: 0px 28px;
}

.home-featured-recent-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(194, 194, 194);
    padding: 16px 0px;
    cursor: pointer;
}

.home-featured-recent-item:last-of-type {
    border-bottom: none;
}

.home-featured-recent-item p {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.home-featured-recent-item div {
    font-size: 14px;
    font-weight: 500;
    color: rgb(90, 90, 90);
}

.home-featured-recent-item svg {
    font-size: 20px;
}

.home-featured-item h3 {
    margin: 0;
    font-weight: 300;
    font-size: 16px;
}

.home-featured-item p {
    font-weight: 600;
    font-size: 32px;
    margin: 12px 0px;
}

.home-featured-get-started-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.home-featured-get-started-section p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.home-featured-get-started-section-button {
    box-sizing: border-box;
    background-color: var(--ml-color-accent);
    color: white;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-size: 14px;
    padding: 4px 14px;
}

.home-subscription-badge {
    font-size: 12px;
    padding: 1px 6px;
    border-radius: 4px;
    color: rgb(29, 59, 110);
    background-color: rgb(200, 214, 237);
    text-transform: capitalize;
}

.home-subscription-badge-active {
    color: rgb(29, 110, 47);
    background-color: rgb(200, 237, 212);
}

.home-subscription-badge-trial {
    color: rgb(110, 29, 98);
    background-color: rgb(237, 200, 233);
}

.home-subscription-badge-canceled {
    color: rgb(110, 29, 29);
    background-color: rgb(237, 200, 200);
}

.home-subscription-badge-beta {
    color: #ffbf42;
    background-color: #474747;
}

.home-page-section-text h3 {
    margin: 0;
    font-size: 14px;
    margin: 0px 12px;
    margin-top: 24px;
    text-transform: uppercase;
}

.home-page-section-text {
    font-weight: 400;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.home-initial-signup-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.home-initial-signup-section h2 {
    margin: 0;
    font-size: 32px;
}

.home-initial-signup-section h3 {
    font-size: 40px;
    margin: 0;
    color: black;
}

.home-initial-signup-section h3 span {
    font-size: 20px;
    color: var(--ml-color-dark);
}

.home-initial-signup-terms-accepted {
    display: flex;
    align-items: center;
    gap: 12px;
}

.home-initial-signup-terms-accepted svg {
    cursor: pointer;
    font-size: 20px;
}

.home-account-management-button {
    margin-top: 24px;
    border: 1px solid var(--ml-color-borders);
    box-shadow: var(--ml-card-box-shadow);
    padding: 12px;
    text-align: center;
    cursor: pointer;
    font-size: var(--ml-font-size);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.privacy-policy {
    padding: 0px 12px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    margin-bottom: 120px;
}

.privacy-policy-section {
    margin-top: 12px;
    margin-bottom: 24px;
}

.privacy-policy p,
.privacy-policy li {
    font-size: 14px;
    line-height: 20px;
}

.privacy-policy p {
    margin-bottom: 16px;
    line-height: 20px;
}

.general-empty-state-container {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
}

.general-empty-state-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.general-empty-state-icon-container {
    --empty-state-icon-width: 60px;
    width: var(--empty-state-icon-width);
    min-width: var(--empty-state-icon-width);
    height: var(--empty-state-icon-width);
    background-color: var(--ml-color-acc);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--empty-state-icon-width);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.general-empty-state-icon-container svg {
    font-size: 24px;
    color: white;
}

.general-empty-state-description h3 {
    color: var(--ml-color-dark);
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.general-empty-state-instructions {
    color: var(--ml-color-dark);
    max-width: 100%;
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    font-size: var(--ml-font-size);
    line-height: 1.5;
    font-weight: 500;
}

.general-empty-state-container button {
    height: 40px;
    border-radius: 4px;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    text-align: center;
    border: none;
    background-color: black;
    color: white;
    font-weight: 500;
    font-size: var(--ml-font-size);
    font-family: var(--ml-font-styles);
    cursor: pointer;
}

.general-empty-state-button-clear {
    color: rgb(51, 51, 51);
    border: 1px solid rgb(197, 197, 197);
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
}

.general-category-page-main {
    border-top: 1px solid var(--ml-color-borders);
    padding-bottom: 80px;
}

.general-category-page-header {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
}

.general-category-page-main h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
}

.general-category-page-create {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    color: white;
    background-color: black;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.general-category-page-create-inverse {
    color: black;
    border: 1px solid black;
    background-color: white;
}

.general-category-page-utilities {
    margin-bottom: 24px;
    padding: 0px 12px;
}

.general-category-page-utility-buttons {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
}

.general-category-page-utility-button {
    border: 1.5px solid rgb(223, 223, 223);
    border-radius: 4px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 8px;
    padding: 12px 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgb(72, 72, 72);
}

.general-category-page-utility-button svg {
    color: grey;
    font-size: 20px;
}

.general-category-page-utility-button-active {
    border: 1.5px solid rgba(22, 42, 71, 0.278);
    background-color: rgba(0, 32, 147, 0.163);
    color: rgb(22, 42, 71);
}

.general-category-page-utility-button-active svg {
    color: rgb(22, 42, 71);
}

.general-category-page-create.candles-category-page-create {
    background-color: var(--ml-color-card-candles);
}

.general-card-list {
    --ml-card-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
}

.category-page-header {
    margin-top: 24px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.category-page-heading-titles h1 {
    margin: 0px 12px;
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 500;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.category-page-heading-titles p {
    color: var(--ml-color-dark);
    font-size: var(--ml-font-size);
    margin: 0px 12px;
}

.category-page-heading-titles {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
}

.general-preview-list-pane-header .category-page-heading-titles {
    width: unset;
}

.general-preview-list-pane-header .general-category-page-create {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(194, 194, 194);
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 16px;
}

.category-page-heading-actions {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-basis: 33%;
}

.category-page-heading-actions {
    width: 100%;
    box-sizing: border-box;
}

.category-page-heading-actions .general-category-page-create {
    flex-grow: 1;
}

.candles-card-list {
    padding: 0px 12px;
}

.general-card-list-recipes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0px 12px;
}

.general-card-list-collections {
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    gap: 28px;
    margin-top: 32px;
    margin-bottom: 40px;
}

.recipe-container-desktop .general-card-list-collections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.general-card-list-text-empty {
    font-size: var(--ml-font-size);
    margin: 0px 12px;
    text-align: center;
    font-style: italic;
    margin-top: 12px;
    margin-bottom: 12px;
}

.general-card {
    max-width: 100%;
    box-sizing: border-box;
    min-height: 80px;
    border-radius: 12px;
    background-color: var(--ml-color-button);
}

.general-card-recipe {
    flex-basis: 45%;
    overflow: hidden;
    margin-bottom: 24px;
    padding: 12px;
    background-color: white;
    cursor: pointer;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border: 1px solid rgba(0, 0, 0, 0.156);
    transform: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.general-card-recipe:hover {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.general-card-favorite-symbol {
    position: absolute;
    bottom: 4px;
    right: 8px;
    color: rgb(73, 73, 73);
}

.general-card-favorite-symbol svg {
    font-size: 20px;
}

.general-card-symbol {
    font-size: 28px;
}

.general-card-symbol svg {
    color: var(--ml-color-card);
}

.general-card-symbol-batch svg {
    color: var(--ml-color-dark);
}

.general-card-symbol-candle svg {
    color: var(--ml-color-light);
}

.general-card.batch-card {
    background-color: var(--ml-color-card-batches);
}

.general-card.candle-card {
    /* flex-basis: 42%; */
    background-color: var(--ml-color-card-candles);
}

.general-card-top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
    box-sizing: border-box;
}

.general-card-top-clickable {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
    box-sizing: border-box;
}

.general-card-top-left {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.general-card-id {
    font-size: 12px;
    color: rgb(69, 69, 69);
    font-weight: 500;
}

.general-card-name {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-weight: 500;
    word-wrap: break-word;
}

.general-card-description {
    font-size: var(--ml-font-size);
    color: var(--ml-color-dark);
    margin-top: 4px;
}

.general-card-description-small {
    font-size: 13px;
    margin-top: 8px;
    color: rgb(135, 135, 135);
    padding-right: 20px;
}

.card-collection {
    flex-basis: 45%;
    border-radius: 4px;
    padding: 18px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.recipe-container-desktop .card-collection {
    flex-basis: 30%;
}

.card-collection h2 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}

.card-collection p {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: rgb(71, 71, 71) !important;
}

.card-recipe-actions {
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-recipe-actions button {
    padding: 8px 24px;
    min-height: 40px;
    background-color: var(--ml-color-card);
    border-radius: 20px;
    font-weight: 700;
    color: var(--ml-color-darkest);
    border: 1px solid var(--ml-color-card);
    font-size: var(--ml-font-size);
    cursor: pointer;
}

.card-recipe-actions.card-batches-actions button {
    background-color: #D2C2EB;
    border: 1px solid #D2C2EB;
}

.card-recipe-actions svg {
    color: var(--ml-color-darkest);
    font-size: 20px;
    cursor: pointer;
}

.batch-card-heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.batch-buffer-pill {
    background-color: gold;
    color: rgb(39, 39, 39);
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}

.batch-update-button {
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--ml-color-dark);
}

.card-batches-pills {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
}

.card-batches-pills div {
    font-size: 10px;
    font-weight: 600;
    padding: 2px 12px;
    background-color: rgb(209, 209, 209);
    border-radius: 4px;
}

.card-batches-badges {
    margin-top: 12px;
}

.badge-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.badge-name {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}

.badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}

.badge span {
    padding: 8px;
    background-color: #2ea7bf;
    border-radius: 10px 14px 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.badge svg {
    font-size: 14px;
    color: white;
}

.slim-candle-card-badge-container {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 4px;
}

.slim-candle-card-badge-container .badge svg {
    color: white;
    font-size: 12px;
}

.general-page-create {
    padding-bottom: 80px;
}

.general-page-recipe {
    padding-bottom: 40px;
}

.general-page-create-section {
    max-width: 100%;
    box-sizing: border-box;
    background-color: white;
    /* border: 1px solid var(--ml-color-borders); */
    border-radius: 6px;
    padding: 12px;
    margin-bottom: 24px;
    margin-left: 4px;
    margin-right: 4px;
}

.general-page-create-section-margin-remove,
.general-page-create-section-margin-remove .recipe-ingredients-list {
    margin-left: 0px;
    margin-right: 0px;
}

.general-page-create-section-borderless {
    border: none;
}

.general-page-create-section-heading {
    padding: 0;
    margin-top: 16px;
}

.general-preview-list-pane-header .general-page-create-section-heading {
    margin-top: 0px;
}

.general-page-create-section-readonly {
    background-color: rgba(40, 40, 40, 0.042);
}

.general-page-create-section-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 12px;
}

.general-page-create-section h1 {
    margin: 4px 12px;
    margin-top: 12px;
    margin-left: 0;
    font-size: 28px;
    font-weight: 500;
}

.general-page-create-section h2 {
    margin: 0 12px;
    font-size: 18px;
    font-weight: 500;
}

.general-page-create-section-summary-card h2 {
    margin-bottom: 16px;
}

.general-page-create-section-summary-card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 12px;
    cursor: pointer;
}

.general-page-create-section-summary-card-title {
    margin-left: 0px;
    font-size: 16px;
    font-weight: 500;
}

.general-page-create-section-summary-card-subtitle {
    font-size: var(--ml-font-size);
    color: var(--ml-color-dark);
}

.general-page-create-select-units-small {
    padding: 4px 8px;
    font-size: 16px;
    background-color: rgb(246, 246, 246);
    border: 1px solid var(--ml-color-accent);
    border-radius: 4px;
    color: var(--ml-color-accent);
    font-weight: 500;
    font-family: var(--ml-font-styles);
}

.header-return-to-page h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.general-page-create-section p {
    color: var(--ml-color-dark);
    font-size: var(--ml-font-size);
    margin: 4px 12px;
    line-height: 1.5;
}

.general-page-create-image-feature {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.general-page-create-image-feature svg {
    font-size: 48px;
    color: var(--ml-color-card);
}

.general-page-create-options-single {
    margin: 0px 12px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.general-page-create-button-adjust {
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.general-page-create-button-adjust svg {
    font-size: 24px;
}

.general-page-create-options-single textarea,
.notes-container textarea,
.modal-create-simple-input textarea {
    font-family: var(--ml-font-styles);
    resize: none;
    outline: var(--ml-color-borders);
    border: 1px solid var(--ml-color-borders);
    padding: 8px;
    font-size: var(--ml-font-size);
}

.notes-container textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: var(--ml-font-size);
    /* color: var(--ml-color-dark); */
    color: rgb(41, 41, 41);
    font-family: var(--ml-font-styles) !important;
}

.general-page-create-option-single {
    border-top: 1px solid var(--ml-color-borders);
    padding: 14px 0px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: var(--ml-font-size);
    font-weight: 400;
}

.general-page-create-option-single-weighted-blends {
    margin: 0;
}

.general-page-create-option-single-weighted-blend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
}

.general-page-create-option-single-weighted-blend span {
    flex-basis: 10%;
    min-width: 40px;
}

input.general-page-create-option-small-input {
    flex-basis: 50%;
    min-width: 40px;
}

.general-page-create-option-small-headings {
    display: flex;
    justify-content: space-between;
    margin: 0px 12px;
    margin-top: 8px;
}

.general-page-create-option-small-headings span {
    font-size: 14px;
    font-weight: 500;
    color: grey;
}

.form-blend-option-remove {
    color: grey;
    cursor: pointer;
    padding: 12px 0px;
}

.form-blend-option-buttons {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 14px;
    margin-left: 12px;
}

.form-blend-option-buttons button {
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    padding: 6px 12px;
    color: black;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
}

.form-blend-option-buttons svg {
    font-size: 20px;
}

.form-blend-option-buttons-inverted button {
    background-color: rgb(70, 70, 70);
    border: 1px solid black;
    color: white;
    font-size: 14px;
}

.general-page-create-option-single svg {
    font-size: 16px;
    cursor: pointer;
}

.general-page-create-option-single:first-child {
    border-top: none;
}

.general-page-create-option-multi-input-text-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 12px;
    width: 100%;
}

.general-page-create-option-multi-input-text-container span {
    flex-basis: 20%;
    min-width: 120px;
}

.general-page-create-option-multi-input-text-container input {
    flex-grow: 1;
    font-family: var(--ml-font-styles);
    padding: 4px;
    font-size: 16px;
}

.general-page-create-section p {
    font-size: var(--ml-font-size);
    color: var(--ml-color-dark);
}

.general-top-right-text-small {
    font-size: 12px;
    color: var(--ml-color-dark);
}

.general-top-right-button-options {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    /* border: 1px solid rgb(119, 119, 119); */
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.general-top-right-button-options svg {
    font-size: 24px;
    color: rgb(119, 119, 119);
}

.general-top-right-button-options:hover {
    background-color: rgb(246, 246, 246);
}

.edit-menu-container {
    position: relative;
}

.edit-menu-options {
    position: absolute;
    right: 70%;
    width: 140px;
    top: 100%;
    z-index: 100;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid rgb(216, 216, 216);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.edit-menu-options div {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.edit-menu-options div:hover {
    background-color: rgb(247, 247, 247);
}

.edit-menu-options div:last-of-type {
    border-bottom: none;
}

.general-page-create-input-text {
    max-width: 100%;
    box-sizing: border-box;
    margin: 0px 12px;
    margin-top: 16px;
}

.general-page-create-input-label {
    font-size: 16px;
    margin-bottom: 4px;
}

.general-page-create-input-text input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: var(--ml-font-size);
    font-style: var(--ml-font-styles);
}

.general-page-create-input-text select {
    font-weight: 400;
    font-size: 16px;
    height: 100%;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--ml-font-styles);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.general-page-create-input-toggle-button-text span {
    font-size: 14px;
    cursor: pointer;
    color: grey;
    padding: 4px;
    padding-left: 0px;
    position: absolute;
}

.general-page-create-input-toggle-button-text {
    position: relative;
}

.general-page-create-input-toggle-button-text span:hover {
    text-decoration: underline;
}

.general-page-create-pill-form-validation {
    font-size: 12px;
    padding: 2px 6px;
    background-color: var(--ml-color-val-yellow);
    color: black;
    font-weight: 500;
    border-radius: 4px;
}

.general-page-create-pill-form-validation.general-page-create-pill-form-validation-optional {
    background-color: rgb(136, 136, 136);
    color: white;
}

.general-page-create-pill-form-validation.general-page-create-pill-form-validation-pass {
    background-color: var(--ml-color-val-green);
    color: white;
}

.general-page-create-button-create {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.general-page-item-unit-selection {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    margin: 18px 12px;
}

.general-page-create-option-label {
    font-size: 15px;
    font-weight: 500;
}

.recipe-badge-decoration {
    margin: 0px 12px;
    padding: 4px 14px;
    background-color: #e9e9e9;
    color: rgb(92, 92, 92);
    border-radius: 24px;
    font-size: 12px;
    font-weight: 500;
}

.recipe-badge-favorite {
    font-size: 28px;
    margin-left: 12px;
    cursor: pointer;
}

.recipe-collections-list {
    margin-top: 40px;
}

.recipe-collections-list h3 {
    margin: 0px 12px;
    font-weight: 300;
    font-size: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(200, 200, 200);
    text-align: center;
}

.general-page-item-unit-selection span {
    padding: 6px 18px;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 400;
    text-align: center;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    color: grey;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.271);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
}

span.general-page-item-unit-selection-selected {
    border: 1px solid rgb(147, 147, 147);
    font-weight: 600;
    color: white;
    background-color: black;
}

.general-page-create-button-create button {
    width: 80%;
    padding: 12px 0px;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    font-size: var(--ml-font-size);
    font-weight: 500;
    cursor: pointer;
}

.general-page-item-view-header {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 13px;
    text-transform: uppercase;
}

.general-page-item-view-header h3 {
    font-size: 13px;
    text-transform: uppercase;
}

.recipe-container-desktop {
    display: none;
    height: 100%;
}

.desktop-only {
    display: none;
}

.general-card-list-recipes-surrogate {
    position: relative;
    height: 60px;
}

.general-card-list-recipe-positions {
    position: fixed;
    bottom: 0;
    left: 12px;
    top: 340px;
    width: calc(var(--preview-list-container-width) - 12px);
    overflow: hidden;
    overflow-y: auto;
}

.general-preview-list-page-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 200px;
    bottom: 0;
}

.general-preview-list-list-container {
    --preview-list-container-width: 400px;
    width: var(--preview-list-container-width);
    position: relative;
}

.general-preview-list-pane-container {
    position: absolute;
    left: 400px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.general-preview-list-pane-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.recipe-ingredients-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    margin: 0px 12px;
}

.recipe-ingredient-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--ml-color-borders);
}

.recipe-ingredient-row-multi {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--ml-color-borders);
}

.recipe-ingredient-row-multi .recipe-ingredient-row {
    border-bottom: none;
}

.recipe-ingredient-row-multi .recipe-ingredient-name {
    font-weight: 500;
    color: rgb(86, 86, 86);
}

.recipe-ingredient-row-multi .recipe-ingredient-name,
.recipe-ingredient-row-multi .recipe-ingredient-value {
    /* font-weight: 300; */
    font-size: 14px;
}

.recipe-ingredient-row-multi .recipe-ingredient-row-primary .recipe-ingredient-value,
.recipe-ingredient-row-multi .recipe-ingredient-row-primary .recipe-ingredient-name {
    font-size: 14px;
    font-weight: 500;
    color: unset;
}

.recipe-ingredient-name {
    font-size: 14px;
    font-weight: 500;
}

.recipe-ingredients-list-detailed .recipe-ingredient-row {
    padding-bottom: 8px;
}

.recipe-ingredients-list-detailed .recipe-ingredient-name {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.recipe-ingredients-list-detailed .recipe-ingredient-name span {
    font-size: 12px;
    color: grey;
    font-weight: 400;
}

.recipe-ingredient-value {
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.recipe-ingredient-value-multi {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.recipe-ingredient-value-highlight {
    /* background-color: rgb(107, 45, 105); */
    background-color: rgb(145, 46, 41);
    color: white;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
}

.general-card-bottom-text-links {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.general-card-bottom-text-links span { 
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.general-list-show-more-button {
    width: 100%;
    background-color: black;
    text-align: center;
    font-size: var(--ml-font-size);
    font-weight: 500;
    padding: 8px 0px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 8px;
    color: white;
}

.general-list-show-more-button-cancel {
    background-color: rgb(224, 224, 224);
    color: rgb(117, 117, 117);
}

.slim-batch-item:last-child {
    display: flex;
    flex-direction: column;
}

.slim-batch-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--ml-color-borders);
    padding: 12px 0px;
    cursor: pointer;
}

.slim-batch-item-container:last-of-type {
    border-bottom: none;
}

.slim-batch-item-container:hover {
    background-color: rgb(247, 247, 247);
}

.general-card-list-candle-squares {
    display: flex;
    flex-direction: column;
}

.candle-card-small-square {
    width: 100%;
    background-color: white;
    border-bottom: 1px solid rgb(214, 214, 214);
    cursor: pointer;
    padding: 8px 0px;
    padding-right: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candle-card-small-recipe {
    font-size: 14px;
    font-weight: 400;
    color: rgb(91, 91, 91);
}

.candle-card-small-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
}

.candle-card-small-wick {
    font-size: 14px;
    color: rgb(91, 91, 91);
    font-weight: 600;
}

.slim-batch-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.slim-batch-item p {
    margin: 0;
    font-size: var(--ml-font-size);
    color: unset;
    font-weight: 600;
    font-size: 14px;
}

.slim-batch-item span {
    font-size: 12px;
    color: var(--ml-color-dark);
}

.slim-batch-item-container svg {
    color: var(--ml-color-dark);
}


/* Misc */
.misc-floating-bottom-button-single-container {
    display: flex;
    justify-content: center;
}

.misc-floating-bottom-button-single-container button {
    width: 80%;
    border: none;
    padding: 12px 0px;
    border-radius: 32px;
    background-color: var(--ml-color-accent);
    color: white;
    border-radius: 24px;
    font-size: var(--ml-font-size);
    font-weight: 600;
    font-family: var(--ml-font-styles);
    cursor: pointer;
}

.misc-floating-error-message-outer {
    position: relative;
}

.misc-floating-error-message {
    position: fixed;
    top: 5%;
    left: 5%;
    right: 5%;
    background-color: rgb(222, 70, 70);
    display: flex;
    justify-content: space-between;
    padding: 12px;
    align-items: center;
    border-radius: 8px;
}

.misc-floating-error-message span {
    font-weight: 500;
    color: white;
}

.misc-floating-error-message svg {
    cursor: pointer;
    padding: 8px;
    color: white;
}

/* Gadgets: Search Bar */
.gadget-general-page-search-bar {
    width: 100%;
    position: relative;
    border: 1px solid grey;
    border-radius: 6px;
}

.gadget-general-page-search-bar svg {
    font-size: 16px;
    color: rgb(79, 79, 79);
    z-index: 2;
    left: 10px;
    top: 16px;
    position: absolute;
}

.gadget-general-page-search-bar input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding-left: 32px;
    font-size: var(--ml-font-size);
    border-radius: 6px;
    font-family: var(--ml-font-styles);
    padding-top: 12px;
    padding-bottom: 12px;
}

.candle-page-badges-section {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

/* INVENTORY PAGE */
.inventory-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 12px;
    gap: 120px;
}

.inventory-nav-desktop {
    border-top: 1px solid var(--ml-color-moonlite-black);
    min-width: 240px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.inventory-nav-desktop span {
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 12px;
    border-top-right-radius: 36px;
    border-bottom-right-radius: 36px;
    cursor: pointer;
}

.inventory-nav-desktop span:hover {
    background-color: rgb(238, 238, 238);
}

.inventory-nav-desktop span.selected {
    background-color: var(--ml-color-accent);
    color: white;
    padding-left: 8px;
}

.inventory-nav-mobile select {
    font-weight: 600;
    height: 100%;
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    width: 200px;
    border-radius: 8px;
    padding-right: 12px;
    cursor: pointer;
    font-family: var(--ml-font-styles);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.inventory-item-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;
    padding-left: 12px;
    padding-right: 12px;
}

.inventory-item-section-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.inventory-item-section-column-60 { flex-basis: 100%; }
.inventory-item-section-column-40 { flex-basis: 100%; }

.inventory-item-section {
    min-height: 36px;
}

.inventory-item-section h3 {
    margin: 0;
    font-weight: 500;
    margin-bottom: 8px;
}

.inventory-item-section-content {
    padding: 16px;
    border: 1px solid var(--ml-color-borders);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inventory-item-input-label {
    font-size: 14px;
    color: grey;
    margin-bottom: 8px;
}

.inventory-item-section-content input {
    width: 100%;
    font-size: 16px;
    padding: 12px 8px;
    box-sizing: border-box;
    border: 1px solid rgb(177, 177, 177);
    border-radius: 4px;
    font-family: var(--ml-font-styles);
}

.inventory-item-section-content textarea {
    font-family: var(--ml-font-styles);
    resize: none;
    outline: var(--ml-color-borders);
    border: 1px solid rgb(177, 177, 177);
    border-radius: 4px;
    padding: 8px;
    font-size: var(--ml-font-size);
    width: 100%;
    box-sizing: border-box;
    min-height: 160px;

}

.inventory-item-section-content p {
    font-size: 16px;
}

.inventory-item-hyperlink {
    color: var(--ml-color-accent);
    cursor: pointer;
}

.inventory-item-hyperlink:hover {
    text-decoration: underline;
}

.inventory-item-multiline {
    white-space: pre-wrap;
    line-height: 1.8;
}

/* Gadgets: Collapsible List Section */
.gadget-collapsible-list-section-headings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 12px 0px;
    --ml-headings-color: rgb(52, 52, 52);
    cursor: pointer;
}

.gadget-collapsible-list-section-headings h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: var(--ml-headings-color);
}

.gadget-collapsible-list-section-headings svg {
    color: var(--ml-headings-color);
    font-size: 28px;
    padding: 6px;
    padding-left: 0px;
}

/* TASKS */
.task-list-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    max-width: 100%;
    padding-left: 8px;
}

.task-outer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
}

.task-interaction-symbol {
    width: 36px;
    min-width: 36px;
    cursor: pointer;
}

.task-interaction-symbol svg {
    height: 20px;
    width: 20px;
    min-width: 20px;
}

.task-interaction-symbol .task-interaction-active-circle-preview,
.task-interaction-symbol:hover .task-interaction-active-circle {
    display: none;
}

.task-interaction-symbol:hover .task-interaction-active-circle-preview {
    display: unset;
}

.task-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;
}

.task-name {
    flex-grow: 1;
    cursor: pointer;
}

.task-name:hover {
    text-decoration: underline;
}

.task-date-due {
}

.task-list-new-task {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 8px;
    margin-bottom: 12px;
    margin-top: 12px;
    position: relative;
    box-sizing: border-box;
}

.task-list-new-task input {
    flex-grow: 1;
    border: none;
    border-bottom: 1px solid var(--ml-color-dark);
    font-size: 16px;
    padding: 8px 0px;
    font-weight: 500;
    color: var(--ml-color-dark);
    font-family: var(--ml-font-styles);
    box-sizing: border-box;
    width: 100%;
}

.recipe-container-desktop .task-list-new-task input {
    padding-right: 220px;
}

.task-list-date-selection-menu {
    position: absolute;
    bottom: calc(100% + 14px);
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 200px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.task-list-date-selection-menu span:first-of-type {
    border-top: none;
}

.task-list-date-selection-menu span {
    border-top: 1px solid var(--ml-color-borders);
    padding: 16px 8px;
    cursor: pointer;
}

.task-list-date-selection-menu span:hover {
    background-color: rgb(243, 243, 243);
}

.task-list-new-task-actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    position: absolute;
    right: 16px;
}

.task-list-new-task-actions svg {
    height: 24px;
    width: 24px;
    min-width: 24px;
    cursor: pointer;
}

/* ADD BADGE */

.form-add-badge-name {
    margin: 0px 12px;
    font-size: 16px;
    font-weight: 500;
}

.form-add-badge-description {
    color: var(--ml-color-dark);
    margin: 0px 12px;
    font-size: var(--ml-font-size);
}

.form-add-badge-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 14px;
}

.form-add-badge-item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    font-size: var(--ml-font-size);
}

.form-add-badge-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.form-add-badge-checkbox svg {
    font-size: 24px;
    color: var(--ml-color-dark);
}

.form-add-badge-badge-name span {
    padding: 4px 12px;
    border-radius: 12px;
    color: white;
}

/* UTILITIES */

.util-row {
    display: flex;
}

.util-space-between {
    justify-content: space-between;
}

.util-align-start {
    align-items: flex-start;
}

.util-align-end {
    align-items: flex-end;
}

.util-align-center { 
    align-items: center;
}

.util-justify-center {
    justify-content: center;
}

.util-justify-end {
    justify-content: flex-end;
}

/* Modals */
.modal-boundary {
    position: relative;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
}

.modal-holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    max-width: 540px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px 12px;
    margin-bottom: 40px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.modal-header h2 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
}

.modal-create-sections {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.modal-edit-sections {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal-create-section h3 {
    /* border-bottom: 1px solid var(--ml-color-dark); */
    font-size: 20px;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 500;
}

.modal-create-inputs-container {
    display: flex;
    flex-direction: column;
    padding: 14px;
    border: 1px solid var(--ml-color-border-dark);
    gap: 24px;
}

.modal-create-simple-input {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modal-create-simple-input h4 {
    margin: 0;
    color: var(--ml-color-dark);
    font-size: 14px;
    font-weight: 500;
}

.modal-create-simple-input input {
    width: 100%;
    padding: 12px;
    font-size: var(--ml-font-size);
    font-family: var(--ml-font-styles);
    box-sizing: border-box;
    border: 1px solid var(--ml-color-borders);
    border-radius: 4px;
    font-size: 16px;
}

.modal-create-simple-input select {
    font-weight: 400;
    font-size: 16px;
    height: 100%;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--ml-font-styles);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-create-simple-input p {
    font-size: 16px;
    font-weight: 400;
    padding: 12px 0px;
}

.modal-edit-section {
    margin-bottom: 48px;
}

.modal-edit-section-instructions p {
    line-height: 2;
    color: var(--ml-color-moonlite-black);
    font-size: 15px;
    margin-bottom: 18px;
}

.modal-edit-section-instructions .modal-edit-section h3 {
    margin-bottom: 0px;
}

.modal-edit-section-instructions .recipe-ingredients-list {
    margin: 0;
}

.modal-edit-section h3 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 24px;
    font-weight: 500;
}

.modal-edit-section-multi-row-inputs {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.modal-edit-section-multi-row-name {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modal-edit-section-multi-row-name span {
    font-size: 14px;
    font-weight: 400;
    color: grey;
}

.modal-edit-section-multi-row-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-edit-section-multi-row-input input {
    flex-grow: 1;
    min-width: 120px;
}

.modal-edit-section input {
    width: 100%;
    padding: 12px;
    font-size: var(--ml-font-size);
    font-style: var(--ml-font-styles);
    box-sizing: border-box;
    border: 1px solid var(--ml-color-borders);
    border-radius: 8px;
}

.modal-header svg {
    font-size: 36px;
    cursor: pointer;
}

.modal-display-text {
    font-size: var(--ml-font-size);
    color: var(--ml-color-darkest);
    line-height: 1.8;
    white-space: pre-wrap;
}

.modal-buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
}

.modal-buttons-alternative {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
}

.modal-buttons-alternative span {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.modal-buttons span {
    padding: 6px 16px;
    background-color: black;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 1;
    border: 1px solid transparent;
    text-align: center;
}

span.modal-button-secondary {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.modal-buttons-small span {
    flex-grow: 0;
    width: 60px;
    border: 1px solid transparent;
}

.modal-buttons-float-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    background-color: white;
}

.modal-edit-error-text {
    font-size: 14px;
    color: rgb(165, 0, 0);
    font-style: italic;
    margin-top: 4px;
    text-align: center;
}

.modal-grid-colors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
}

.modal-grid-colors div {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 24px;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.collection-color-dot {
    margin-left: 12px;
    width: 6px;
    min-width: 6px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.modal-collection-recipe-list-scrolls {
    margin-bottom: 60px;
}

.modal-blend-edit-inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.modal-blend-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
}

.modal-blend-item-weight {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 4px;
}

.modal-blend-item-weight input {
    width: 60px;
    padding: 8px 0px;
    font-size: 16px;
    border: none;
    text-align: center;
    border-bottom: 1px solid black;
    background-color: transparent;
    min-width: 60px;
}

.modal-blend-allocation-statement{
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin-top: 24px;
}

.modal-blend-allocate-buttons {
    display: flex;
    justify-content: center;
    margin: 16px 0px;
}

.modal-filter-main {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.modal-filter-controls {
    display: flex;
    justify-content: center;
}

.modal-filter-controls span {
    cursor: pointer;
    padding: 8px 12px;
    border: 1px solid rgb(60, 60, 60);
    border-radius: 4px;
}

.modal-filter-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.modal-filter-category-header h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
}

.modal-filter-category-header div {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.modal-filter-category-items {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    margin-top: 16px;
}

.modal-filter-category-items.modal-filter-category-items-pills {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.modal-filter-category-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.modal-filter-category-items-pills .modal-filter-category-item {
    justify-content: center;
    gap: 12px;
    border: 1px solid grey;
    padding: 4px 0px;
    border-radius: 24px;
    flex-basis: 45%;
    cursor: pointer;
}

.modal-filter-category-item-name {
    font-size: 16px;
    font-weight: 300;
}

.modal-filter-category-items-pills .modal-filter-category-item .modal-filter-category-item-name {
    font-weight: 500;
}

.modal-filter-category-item svg {
    font-size: 20px;
    cursor: pointer;
}

.modal-filter-category-items-pills .modal-filter-category-item svg {
    color: white;
}

/* TABLES */
.general-table-container-full-width {
    width: 100%;
}

.general-table-flex-row {
    display: flex;
    border-bottom: 1px solid var(--ml-color-borders);
    border-left: 1px solid var(--ml-color-borders);
    border-right: 1px solid var(--ml-color-borders);
}

.general-table-flex-row:first-of-type {
    border-top: 1px solid var(--ml-color-borders);
}

.general-table-col {
    overflow: hidden;
    font-size: 14px;
    padding: 16px 2px;
    box-sizing: border-box;
    text-overflow: ellipsis;
}

.general-table-col-clickable {
    cursor: pointer;
    color: var(--ml-color-accent);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.general-table-col-underbadge {
    margin-top: 4px;
    overflow: hidden;
    display: flex;
    max-width: 200px;
}

.general-table-col-underbadge span {
    font-size: 12px;
    padding: 0px 0px;
    color: grey;
    border-radius: 32px;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre;
    text-overflow: ellipsis;
}

.general-table-heading-sortable {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}

.general-table-heading-sortable svg {
    font-size: 16px;
}

.general-table-col-clickable:hover,
.general-supply-clickable:hover,
.general-supply-clickable-title:hover {
    text-decoration: underline;
    text-decoration-style: dashed;
}

.general-supply-clickable {
    cursor: pointer;
    font-weight: 400;
    color: var(--ml-color-accent);
}

.general-supply-clickable-title {
    cursor: pointer;
    color: var(--ml-color-accent);
}

.general-table-headings {
    background-color: rgb(242, 242, 242);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-right: 12px;
    margin-left: 12px;
    box-sizing: border-box;
    max-width: 100%;
}

.general-table-headings .general-table-col {
    display: flex;
    align-items: flex-end;
}

.general-table-rows-surrogate {
    position: fixed;
    left: 20px;
    right: 20px;
    top: 292px;
    bottom: 40px;
    overflow-y: auto;
    box-sizing: border-box;
}

.general-table-headings .general-table-col {
    font-size: 14px;
    color: rgb(54, 54, 54);
    font-weight: 500;
    padding: 6px 2px;
    box-sizing: border-box;
}

.general-table-flex-row.general-table-row-content:first-of-type {
    border-top: none;
}

.general-table-row-content:last-of-type {
    margin-bottom: 60px;
}

.responsive-recipe-new-recipe-button {
    display: none;
}

.general-table-col-grow { flex-grow: 1 }
.general-table-col-5 { flex-basis: 5%; }
.general-table-col-8 { flex-basis: 8%; }
.general-table-col-10 { flex-basis: 10%; }
.general-table-col-15 { flex-basis: 15%; }
.general-table-col-20 { flex-basis: 20%; }
.general-table-col-25 { flex-basis: 25%; }
.general-table-col-30 { flex-basis: 30%; }
.general-table-col-40 { flex-basis: 40%; }
.general-table-col-50 { flex-basis: 50%; }
.general-table-col-60 { flex-basis: 60%; }

.general-table-col-stacked-entries {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.general-table-button-inset {
    padding: 4px 12px;
    border: 1px solid grey;
    color: grey;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
    display: none;
}

.general-table-row-content:hover .general-table-button-inset {
    display: flex;
}

/* Spinning stuff */
.awesome-spinner {
    width: 36px;
    height: 36px;
    -webkit-animation: spin 6s linear infinite;
    -moz-animation: spin 6s linear infinite;
    animation: spin 6s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* CSS Loaders */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--ml-color-accent);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

@media only screen and (min-width: 600px) {
    .category-page-header {
        margin-top: 24px;
        margin-bottom: 24px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    
    .category-page-heading-titles h1 {
        margin: 0px 12px;
        margin-bottom: 12px;
        font-size: 28px;
        font-weight: 500;
    }
    
    .category-page-heading-titles p {
        color: var(--ml-color-dark);
        font-size: var(--ml-font-size);
        margin: 0px 12px;
    }
    
    .category-page-heading-titles {
        flex-grow: 1;
    }
    
    .category-page-heading-actions {
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: unset;
    }

    .category-page-heading-actions .general-category-page-create {
        flex-grow: unset;
    }
}

@media only screen and (min-width: 800px) {
    .general-card-recipe {
        flex-basis: 30%;
    }

    .inventory-item-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 24px;
    }
    
    .inventory-item-section-column {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .inventory-item-section-column-60 { flex-basis: 60%; }
    .inventory-item-section-column-40 { flex-basis: 40%; }
}


/* DESKTOP MODE */
@media only screen and (min-width: 1200px) {

    .moonlite-main {
        margin: 0 auto;
        max-width: 1200px;
    }

    .home-container {
        margin-top: 0;
    }

    .general-category-page-main {
        position: relative;
        padding-bottom: 0;
    }

    .responsive-navigation-bar {
        display: none;
    }

    .header-navigation-container {
        display: flex;
    }

    .general-page-create {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .general-page-preview-pane {
        width: 100%;
        max-width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .responsive-recipe-container,
    .mobile-only {
        display: none;
    }

    .recipe-container-desktop,
    .desktop-only {
        display: block;
        position: relative;
    }

    .general-card-list-recipes {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0px 12px;
        
    }

    .general-card-list-recipes-surrogate .general-card-recipe {
        flex-basis: 100%;
        margin-bottom: 0px;
        border-radius: 0px;
        box-shadow: none;
        border-bottom: none;
    }

    .general-card-list-recipes.general-card-list-recipes-archived-desktop {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .general-card-list-recipes.general-card-list-recipes-archived-desktop .general-card-recipe {
        flex-basis: 30%;
    }

    .general-card-recipe:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.156);
        margin-bottom: 40px;
    }

    .general-card-name {
        margin-bottom: 4px;
    }

    .general-card-id {
        font-size: 14px;
    }

    .login-container {
        max-width: 100%;
    }

    .login-hero-text-container {
        text-align: left;
        justify-content: space-between;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .login-hero-text-desktop {
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
    }

    .login-hero-text-desktop .login-button-google,
    .login-hero-text-desktop h2,
    .login-hero-text-desktop p {
        margin: 0;
    }
    
    .login-hero-text-desktop h2 {
        font-size: 56px;
        text-align: center;
        max-width: 800px;
    }

    .login-hero-text-desktop p {
        font-size: 24px;
        max-width: 800px;
        text-align: center;
    }

    .login-hero-text-desktop .login-button-google {
        width: auto;
        padding: 12px 60px;
    }

    .login-section {

    }

}

@media screen and (max-width: 768px) {
    .general-table-headings .general-table-col {
        font-size: 11px;
    }

    .general-table-col {
        font-size: 11px;
    }

    .responsive-recipe-new-recipe-button {
        display: flex;
    }
}

.inventory-filter-dropdown {
    position: relative;
    display: inline-block;
}

.inventory-filter-dropdown .recipe-table-filter-button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.inventory-select-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
    .inventory-filter-dropdown {
        width: 100%;
    }
    
    .inventory-filter-dropdown .recipe-table-filter-button {
        width: 100%;
        justify-content: space-between;
    }
}