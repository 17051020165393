.batch-plan-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.batch-plan-section h3 {
    margin: 0;
    color: var(--ml-color-darkest);
    font-size: 18px;
    font-weight: 500;
}

.batch-plan-unit-selector {
    display: flex;
    gap: 8px;
    align-items: center;
}

.batch-plan-unit-option {
    background: none;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: var(--ml-color-dark);
    transition: background-color 0.2s ease;
}

.batch-plan-unit-option.selected {
    background-color: rgb(236, 236, 236);
}

.batch-plan-unit-option:hover:not(.selected) {
    background-color: rgb(246, 246, 246);
}

.batch-plan-fields {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.batch-plan-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.batch-plan-label {
    color: var(--ml-color-dark);
    font-size: 14px;
}

.batch-plan-pill {
    padding: 4px;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    min-width: 60px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    transition: background-color 0.2s ease;
    border-radius: 6px;
}

.batch-plan-pill:hover {
    background-color: rgb(236, 236, 236);
}

.batch-plan-edit-icon {
    color: var(--ml-color-dark);
    font-size: 16px;
    opacity: 0.7;
}

.batch-plan-recipe-notes {
    margin: 0;
    font-size: 14px;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--ml-color-subtle-text-background);
    border-radius: 4px;
    color: var(--ml-color-darkest);
    font-weight: 400;
    white-space: pre-wrap;
    max-width: 800px;
}

/* Toggle Switch Styles */
.batch-plan-toggle {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.batch-plan-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.batch-plan-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ml-color-borders);
    transition: .4s;
    border-radius: 24px;
}

.batch-plan-toggle-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.batch-plan-toggle input:checked + .batch-plan-toggle-slider {
    background-color: var(--ml-color-accent);
}

.batch-plan-toggle input:checked + .batch-plan-toggle-slider:before {
    transform: translateX(20px);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .batch-desktop-split {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }

    .batch-desktop-split-section {
        margin-bottom: 20px;
        width: 100%;
        padding-top: 0;
    }

    .batch-plan-field {
        padding: 0;
    }

    .batch-plan-fields {
        gap: 14px;
    }
    
} 