.notification-setup-content {
    padding: 0 24px;
}

.notification-setup-description {
    color: var(--ml-color-dark);
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;
}

.notification-cure-length {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
    width: 100%;
}

.notification-cure-length-controls {
    display: flex;
    align-items: center;
    gap: 16px;
}

.notification-cure-length-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 80px;
}

.notification-cure-length-value {
    font-size: 24px;
    font-weight: 500;
    color: var(--ml-color-dark);
}

.notification-cure-length-label {
    font-size: 14px;
    color: var(--ml-color-subtle-text);
}

.notification-adjust-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--ml-color-borders);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.notification-adjust-button:hover {
    background: var(--ml-color-subtle-text-background);
}

.notification-cure-length input[type="range"] {
    width: 100%;
    max-width: 300px;
    margin: 0;
}

.notification-cure-length input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: var(--ml-color-borders);
    border-radius: 2px;
}

.notification-cure-length input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--ml-color-accent);
    margin-top: -6px;
    cursor: pointer;
}

.notification-cure-length input[type="range"]:focus {
    outline: none;
}

.notification-date-preview {
    font-size: 14px;
    color: var(--ml-color-subtle-text);
    text-align: center;
}

.notification-custom-date {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.notification-custom-button {
    background: none;
    border: none;
    color: var(--ml-color-accent);
    font-size: 14px;
    cursor: pointer;
    padding: 8px 16px;
}

.notification-custom-button:hover {
    text-decoration: underline;
}

.notification-date-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.notification-date-picker input {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--ml-color-borders);
    border-radius: 8px;
    font-size: 14px;
    font-family: var(--ml-font-family);
}

.notification-preset-link {
    background: none;
    border: none;
    color: var(--ml-color-accent);
    font-size: 14px;
    cursor: pointer;
}

.notification-preset-link:hover {
    text-decoration: underline;
}

.notification-error {
    color: var(--ml-color-error);
    font-size: 14px;
    margin-bottom: 24px;
    text-align: center;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.notification-confirm-button {
    background: var(--ml-color-darkest);
    color: white;
    border: none;
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 140px;
}

.notification-confirm-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.notification-confirm-button:not(:disabled):hover {
    filter: brightness(0.95);
}

.notification-cancel-button {
    background: none;
    border: 1px solid var(--ml-color-borders);
    color: var(--ml-color-dark);
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 100px;
}

.notification-cancel-button:hover {
    background: var(--ml-color-subtle-text-background);
}

.notification-delete-button {
    background: none;
    border: 1px solid var(--ml-color-val-red);
    color: var(--ml-color-val-red);
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.notification-delete-button:hover {
    background: var(--ml-color-val-red);
    color: white;
}

@media (max-width: 480px) {
    .notification-cure-length-controls {
        width: 100%;
        justify-content: space-between;
        padding: 0 16px;
    }

    .modal-buttons {
        flex-direction: column;
        gap: 12px;
    }

    .notification-confirm-button,
    .notification-cancel-button,
    .notification-delete-button {
        width: 100%;
        margin-right: 0;
        min-width: unset;
    }

    /* Explicit order for mobile */
    .notification-confirm-button {
        order: 1;  /* Set Notification */
    }

    .notification-cancel-button {
        order: 2;  /* Cancel */
    }

    .notification-delete-button {
        order: 3;  /* Remove Notification */
    }
} 