.inventory-supply-item-select {
    appearance: none;
    background-color: white;
    border: 1px solid rgb(177, 177, 177);
    border-radius: 4px;
    color: var(--ml-color-text);
    cursor: pointer;
    font-size: 14px;
    padding: 12px 8px;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 10px;
}

.inventory-supply-item-select:hover {
    border-color: var(--ml-color-border-hover);
}

.inventory-supply-item-select:focus {
    border-color: var(--ml-color-accent);
    outline: none;
}

/* Style for the select container to ensure proper spacing */
.inventory-supply-item-select-container {
    width: 100%;
    position: relative;
}

/* Unit Pricing Input Styles */
.inventory-price-input-container {
    position: relative;
    width: 100%;
}

.inventory-price-input-container input {
    width: 100%;
    padding: 12px 8px;
    padding-left: 24px !important;
    border: 1px solid rgb(177, 177, 177);
    border-radius: 4px;
    font-size: 14px;
}

.inventory-price-input-container input:focus {
    border-color: var(--ml-color-accent);
    outline: none;
}

.inventory-price-input-container .currency-symbol {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--ml-color-text);
    font-size: 14px;
    pointer-events: none;
} 