.batch-header-container {
    width: 100%;
}

.batch-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 8px;
    box-sizing: border-box;
    margin: 0 12px;
}

.batch-title-row h1 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.batch-subtitle {
    text-align: left;
    margin: 16px 12px;
}

.batch-subtitle-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.batch-subtitle p {
    color: var(--ml-color-dark);
    font-size: 14px;
}

.batch-page-container-mobile {
    display: block;
}

.batch-page-container-desktop {
    display: none;
}

.batch-desktop-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    box-sizing: border-box;
}

.batch-desktop-heading h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
}

.batch-desktop-heading-settings {
    width: 28px;
    height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--ml-color-borders);
    border-radius: 4px;
    cursor: pointer;
}

.batch-desktop-heading-settings svg {
    font-size: 16px;
    color: var(--ml-color-dark);
}

.batch-desktop-cure-timer-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
}

.batch-desktop-cure-timer-container p {
    margin: 0;
    font-size: 14px;
    color: var(--ml-color-dark);
    padding-left: 12px;
}

.batch-desktop-navigation-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--ml-color-borders);
    padding-bottom: 12px;
    margin: 0 12px;
    box-sizing: border-box;
    gap: 36px;
}

.batch-desktop-navigation-bar span {
    font-size: 16px;
    font-weight: 500;
    color: var(--ml-color-dark);
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.batch-desktop-navigation-bar span:first-of-type {
    text-align: left;
}

.batch-desktop-navigation-bar span.selected {
    font-weight: 600;
    color: var(--ml-color-darkest)
}

.batch-desktop-split {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;
    margin: 0px 12px;
    box-sizing: border-box;
}

.batch-desktop-split-section {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;
    padding-top: 24px;
}

.batch-desktop-split-section h2 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 14px;
    font-weight: 500;
    color: var(--ml-color-darkest);
}

.batch-desktop-clickable-item-title {
    color: var(--ml-color-accent);
    font-size: 14px;
    cursor: pointer;
}

.batch-desktop-clickable-item-title:hover {
    text-decoration: underline;
    text-decoration-style: dashed;
}

.batch-desktop-clickable-item-title.candle {
    padding: 6px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.batch-desktop-candle-list .batch-desktop-clickable-item-title.candle:hover {
    text-decoration: none;
    background-color: var(--ml-color-subtle-text-background);
}

.batch-desktop-candle-list .batch-desktop-clickable-item-title.candle span {
    display: none;
}

.batch-desktop-candle-list .batch-desktop-clickable-item-title.candle:hover span {
    display: block;
}

.batch-desktop-split-section .recipe-ingredients-list,
.batch-desktop-split-section .general-page-create-section {
    padding: 0;
}

.batch-desktop-split-section-backgrounds {
    border-left: 1px solid var(--ml-color-borders);
    padding-left: 36px;
    box-sizing: border-box;
    max-width: 400px;
}

.batch-desktop-production-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    gap: 20px;
}

.batch-desktop-production-empty-button {
    color: white;
    background-color: var(--ml-color-darkest);
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
    padding: 12px 40px;
}

.batch-desktop-production-empty-button-text {
    color: var(--ml-color-dark);
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .batch-title-row {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    .batch-title-row h1 {
        font-size: 22px;
        flex: 1;
    }

    .batch-subtitle {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1200px) {
    .batch-page-container-mobile {
        display: none;
    }

    .batch-page-container-desktop {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .general-page-batch-container.general-page-create {
        max-width: unset;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {
    .general-page-create-section .batch-title-row h1 {
        font-size: 18px;
        white-space: normal;
        overflow: visible;
    }
}

.batch-plan-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    background: var(--ml-color-lightest);
    border-radius: 8px;
}

.batch-plan-create,
.batch-plan-duplicate {
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.batch-plan-create {
    background-color: var(--ml-color-accent);
    color: white;
}

.batch-plan-create:hover {
    filter: brightness(0.95);
}

.batch-plan-create:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.batch-plan-duplicate {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.batch-plan-duplicate:hover {
    background-color: var(--background-color-hover);
}

.batch-mobile-section h3,
.batch-desktop-section h3 {
    margin: 0 0 24px 0;
    color: var(--ml-color-darkest);
    font-size: 20px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .batch-mobile-section,
    .batch-desktop-section {
        padding: 16px;
        margin-bottom: 16px;
    }

    .batch-mobile-section {
        margin-top: 0;
        padding-top: 0;
    }

    .batch-mobile-section h3,
    .batch-desktop-section h3 {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .batch-plan-actions {
        padding: 12px;
    }

    .batch-desktop-split {
        gap: 20px;
    }
}

.batch-desktop-heading-controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.batch-desktop-heading-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--ml-color-subtle-text);
    transition: all 0.2s ease;
}

.batch-desktop-heading-notification:hover {
    background: var(--ml-color-subtle-text-background);
}

.batch-desktop-heading-notification svg {
    font-size: 20px;
}

.batch-desktop-heading-notification svg.active {
    color: var(--ml-color-accent);
}

.batch-desktop-status-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-grow: 1;
}

.batch-notification-status {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background: var(--ml-color-subtle-text-background);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 12px;
    color: var(--ml-color-dark);
}

.batch-notification-status:hover {
    background: var(--ml-color-borders);
}

.batch-notification-status svg {
    font-size: 16px;
    color: var(--ml-color-accent);
}

.batch-notification-cancel {
    background: none;
    border: none;
    color: var(--ml-color-subtle-text);
    font-size: 18px;
    line-height: 1;
    padding: 0 4px;
    cursor: pointer;
    margin-left: 4px;
}

.batch-notification-cancel:hover {
    color: var(--ml-color-error);
}

.batch-desktop-notification-link {
    margin-top: 20px;
    color: var(--ml-color-accent);
    font-size: 14px;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.batch-desktop-notification-link:hover {
    opacity: 0.8;
}

.batch-mobile-notification-link {
    color: var(--ml-color-accent);
    font-size: 12px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    white-space: nowrap;
}

.batch-mobile-notification-link:hover {
    opacity: 0.8;
}

.batch-cogs-breakdown {
    margin-top: 8px;
    margin-bottom: 16px;
}

.batch-cogs-breakdown-table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    background-color: rgba(250, 250, 250, 0.5);
    border-radius: 4px;
    overflow: hidden;
}

.batch-cogs-breakdown-table thead {
    background-color: rgba(0, 0, 0, 0.05);
}

.batch-cogs-breakdown-table th,
.batch-cogs-breakdown-table td {
    padding: 6px 10px;
    text-align: left;
}

.batch-cogs-breakdown-table th:last-child,
.batch-cogs-breakdown-table td:last-child {
    text-align: right;
}

.batch-cogs-breakdown-table tbody tr:last-child td {
    border-bottom: none;
}

.batch-cogs-total-row {
    border-top: 1px solid var(--ml-color-borders);
    background-color: rgba(248, 248, 248, 0.8);
}

.batch-cogs-total-row td,
.batch-cogs-breakdown-table td {
    font-weight: 500;
    padding-top: 8px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px;
}

/* Batch rename styles */
.batch-desktop-heading h1:hover,
.batch-title-row h1:hover {
    color: var(--ml-color-primary);
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
}