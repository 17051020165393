.protected-route-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.protected-route-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    min-height: 200px;
}

.protected-route-error-container p {
    color: var(--ml-color-moonlite-black);
    margin-bottom: 1rem;
    font-size: 1rem;
}

.protected-route-error-container button {
    padding: 0.5rem 1.5rem;
    background: var(--ml-color-accent);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s ease;
}

.protected-route-error-container button:hover {
    background: var(--ml-color-accent-dark);
} 