/* InventoryMain.css */

/* Base styles for both mobile and desktop */
.inventory-controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.inventory-search-row,
.inventory-filter-row {
    display: flex;
    align-items: center;
}

.inventory-search-row {
    flex: 1;
    min-width: 200px;
}

.inventory-filter-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
}

.create-button-icon {
    display: none;
}

.mobile-create-button {
    display: none;
}

.inventory-mobile-create {
    display: none;
}

/* Apply box-sizing to all inputs to prevent overflow */
input, select, button {
    box-sizing: border-box;
}

/* Style for general buttons (including settings) */
.general-category-page-button {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--ml-color-borders);
    color: var(--ml-color-dark);
    transition: all 0.2s ease;
}

.general-category-page-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.general-category-page-button svg {
    font-size: 16px;
}

/* Mobile specific styles */
@media (max-width: 768px) {
    /* Hide the header on mobile */
    .general-category-page-header {
        display: none !important;
    }
    
    .batch-table-container {
        padding-top: 12px !important;
    }
    
    .inventory-controls-container {
        flex-direction: column;
        align-items: stretch;
        gap: 12px;
    }
    
    .inventory-search-row, 
    .inventory-filter-row {
        padding: 0 12px;
    }
    
    .inventory-filter-row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 12px;
        position: relative;
    }
    
    .recipe-table-collections {
        flex-grow: 1;
        margin: 0;
    }
    
    .inventory-filter-dropdown {
        width: 100%;
    }
    
    .inventory-mobile-create {
        display: flex;
        justify-content: flex-end;
    }

    .general-category-page-create.mobile-create-button {
        padding: 0;
    }
    
    .mobile-create-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 38px;
        min-width: 38px;
        height: 38px;
        padding: 0;
        background-color: var(--ml-color-darkest, #000);
        color: white;
        cursor: pointer;
        font-size: 18px;
    }
    
    .mobile-create-button svg {
        margin: 0;
    }
    
    .create-button-text {
        display: none;
    }
    
    .create-button-icon {
        display: block;
    }
    
    /* Make search bar take full width on mobile */
    .inventory-search-row .batch-table-search-container {
        width: 100%;
    }
    
    .inventory-search-row .batch-table-search {
        width: 100%;
        height: 38px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-left: 12px;
        box-sizing: border-box;
    }
    
    /* Improve filter dropdown appearance */
    .recipe-table-filter-button {
        height: 38px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        box-sizing: border-box;
    }
    
    /* Ensure placeholder text is correctly styled */
    .batch-table-search::placeholder {
        color: #999;
    }
    
    /* Fix table alignment to match the filter controls */
    .batch-table-wrapper {
        padding: 0 12px;
    }
    
    .mobile-settings-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 38px;
        min-width: 38px;
        height: 38px;
        padding: 0;
        background-color: white;
        border: 1px solid var(--ml-color-borders);
        color: var(--ml-color-dark);
        cursor: pointer;
        margin-right: 8px;
    }
    
    .mobile-settings-button svg {
        margin: 0;
    }
}

/* Desktop specific overrides */
@media (min-width: 769px) {
    .inventory-controls-container {
        flex-wrap: nowrap;
        gap: 16px;
    }
    
    .inventory-filter-dropdown {
        margin-right: 0;
    }
    
    .recipe-table-collections {
        display: flex;
        align-items: center;
    }
    
    .inventory-filter-row {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
} 