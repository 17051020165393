/* General Styles */
/* Using existing color variables */
.landing-container {
  max-width: 100%;
  overflow: hidden;
  font-family: var(--ml-font-styles);
  color: var(--text-color);
  line-height: 1.6;
}

/* Common Elements */
.landing-container h1, 
.landing-container h2, 
.landing-container h3, 
.landing-container h4 {
  margin-top: 0;
  line-height: 1.2;
}

.landing-container h1 {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 20px;
}

.landing-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.landing-container h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.landing-container h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.landing-container p {
  margin: 0 0 16px;
}

.landing-container section {
  padding: 80px 20px;
  margin-top: 120px;
}

.landing-container section:first-of-type {
    margin-top: unset;
}

.landing-container section:nth-child(even) {
  background-color: var(--background-color-hover);
}

/* Buttons */
.landing-cta-button {
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  width: 220px;
  border: 2px solid transparent;
}

.landing-cta-primary {
  background-color: var(--ml-color-darkest);
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.landing-cta-secondary {
  background-color: var(--background-color);
  color: var(--ml-color-darkest);
  border: 2px solid var(--ml-color-darkest);
}

.landing-cta-secondary:hover {
  background-color: var(--background-color-hover);
}

/* Hero Section */
.landing-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  text-align: center;
}

.landing-hero-content {
  max-width: 800px;
  margin-bottom: 40px;
}

.landing-hero h1 {
  color: var(--ml-color-darkest);
  margin-bottom: 24px;
  line-height: 1.1;
  font-size: 50px;
  font-weight: 700;
}

p.landing-hero-subtitle {
  font-size: 20px;
  margin-bottom: 32px;
  opacity: 0.9;
  color: var(--ml-color-dark);
}

.landing-hero-cta {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: center;
}

.landing-hero-note {
  font-size: 14px;
  color: var(--ml-color-dark);
  margin-top: 10px;
  text-align: center;
}

.landing-hero-image {
  position: relative;
  flex: 1;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 12px;
}

.landing-hero-image img {
  border: 1px solid var(--ml-color-borders);
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.landing-hero-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.landing-hero-image:hover .landing-hero-image-overlay {
  opacity: 1;
  pointer-events: auto;
}

.landing-hero-play-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.landing-hero-play-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--ml-color-darkest);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  opacity: 0.9;
  transition: all 0.2s ease;
  font-size: 18px;
}

.landing-hero-play-button:hover .landing-hero-play-icon {
  transform: scale(1.1);
  opacity: 1;
}

/* Social Proof Section */
.landing-social-proof {
  background-color: var(--background-color);
  padding: 40px 20px;
  text-align: center;
}

.landing-social-proof-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-social-proof p {
  font-size: 18px;
  color: var(--ml-color-dark);
  margin-bottom: 24px;
}

.landing-social-proof-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

/* Pain Points Section */
.landing-pain-points {
  padding: 80px 20px;
}

.landing-pain-points-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-pain-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 40px;
  justify-content: center;
}

.landing-pain-item {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  padding: 30px;
  border-radius: 8px;
  background-color: var(--background-color);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.landing-pain-item h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 17px;
  color: var(--text-color);
}

.landing-pain-item ul {
  padding-left: 20px;
}

.landing-pain-item li {
  margin-bottom: 12px;
  position: relative;
  font-size: 14px;
}

.landing-solution {
  background-color: var(--ml-color-darkest);
  color: white;
}

.landing-solution h3 {
  color: white;
}

/* Features Section */
.landing-features {
  padding: 80px 20px;
}

.landing-features-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px;
  margin-top: 40px;
}

.landing-feature-card {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.landing-feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.landing-feature-icon {
  font-size: 16px;
  margin-bottom: 14px;
  color: white;
  background-color: var(--ml-color-darkest);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.landing-feature-card h3 {
  margin-bottom: 15px;
  font-size: 16px;
}

.landing-feature-card p {
  margin-bottom: 0;
  color: var(--ml-color-dark);
  flex-grow: 1;
}

/* Testimonials Section */
.landing-testimonials {
  padding: 80px 20px;
  background-color: var(--background-color-hover);
  box-sizing: border-box;
}

.landing-testimonials-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.landing-testimonial {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-testimonial p {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
}

.landing-testimonial-author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.landing-testimonial-author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.landing-testimonial-author span {
  font-weight: 600;
  color: var(--text-color);
}

/* Pricing Section */
.landing-pricing {
  padding: 80px 20px;
}

.landing-pricing-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.landing-pricing-content > p {
  font-size: 18px;
  color: var(--ml-color-dark);
  margin-bottom: 40px;
}

.landing-pricing-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.landing-pricing-card {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 40px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
}

.landing-pricing-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.landing-pricing-card-featured {
  border: 2px solid var(--ml-color-darkest);
  z-index: 1;
}

.landing-pricing-card-featured:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.landing-pricing-card-badge {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--ml-color-darkest);
  color: white;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.landing-pricing-amount {
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;
}

.landing-pricing-currency {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}

.landing-pricing-number {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

.landing-pricing-period {
  font-size: 16px;
  color: var(--ml-color-dark);
  align-self: flex-end;
}

.landing-pricing-annual {
  font-size: 14px;
  color: var(--ml-color-dark);
  margin-bottom: 20px;
}

.landing-pricing-description {
  color: var(--ml-color-dark);
  margin-bottom: 30px;
}

.landing-pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
  text-align: left;
  flex-grow: 1;
}

.landing-pricing-features li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.landing-pricing-features li svg {
  color: var(--ml-color-val-green);
  flex-shrink: 0;
}

.landing-pricing-guarantee {
  font-size: 14px;
  color: var(--ml-color-dark);
}

.landing-pricing-button-container {
  margin-top: auto;
}

.landing-cta-button[disabled] {
  opacity: 0.7;
  cursor: default;
}

/* FAQ Section */
.landing-faq {
  padding: 80px 20px;
}

.landing-faq-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-faq-two-column {
  display: flex;
  gap: 60px;
  align-items: flex-start;
}

.landing-faq-heading {
  flex: 0 0 30%;
}

.landing-faq-heading h2 {
  margin-bottom: 20px;
  text-align: left;
}

.landing-faq-heading p {
  color: var(--ml-color-dark);
  font-size: 16px;
  line-height: 1.6;
}

.landing-faq-questions {
  /* flex: 0 0 70%; */
  flex-grow: 1;
}

.landing-faq-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  width: 100%;
}

.landing-faq-item {
  background-color: var(--background-color);
  border-radius: 8px;
  margin-bottom: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.landing-faq-question {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  box-sizing: border-box;
}

.landing-faq-question h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.landing-faq-toggle {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.landing-faq-answer {
  padding: 0 20px 15px;
  width: 100%;
  box-sizing: border-box;
}

.landing-faq-answer p {
  margin: 0;
  color: var(--ml-color-dark);
  font-size: 14px;
}

/* Final CTA Section */
.landing-final-cta {
  padding: 100px 20px;
  background-color: var(--background-color-hover);
  color: var(--text-color);
  text-align: center;
}

.landing-final-cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.landing-final-cta h2 {
  color: var(--text-color);
  margin-bottom: 20px;
}

.landing-final-cta p {
  font-size: 18px;
  margin-bottom: 30px;
}

.landing-cta-note {
  font-size: 14px;
  margin-top: 15px;
}

/* Video Popup */
.landing-video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.landing-video-popup-content {
  position: relative;
  max-width: 90%;
  width: 1200px;
  aspect-ratio: 16/9;
  background-color: var(--background-color);
}

.landing-video-popup-close {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.landing-video-popup-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Image Modal */
.landing-image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.landing-image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  background-color: var(--background-color);
  border-radius: 8px;
  overflow: hidden;
}

.landing-image-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  font-size: 24px;
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1001;
}

.landing-image-modal-img {
  display: block;
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
}

/* Make feature section images clickable */
.landing-feature-section-image img {
  max-width: 100%;
  border-radius: 8px;
  object-fit: contain;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.landing-feature-section-image img:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Feature sections with screenshots */
.landing-feature-section {
  display: flex;
  align-items: center;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto 60px;
  margin-top: 120px;
  gap: 40px;
  background-color: var(--background-color);
  border-radius: 12px;
  border: 1px solid var(--ml-color-borders);
}

.landing-feature-section:nth-child(odd) {
  flex-direction: row;
}

.landing-feature-section:nth-child(even) {
  flex-direction: row;
}

.landing-feature-section-content {
  flex: 0.8;
  padding-right: 20px;
}

.landing-feature-section-content h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--text-color);
}

.landing-feature-section-content p {
  margin-bottom: 0;
  color: var(--ml-color-dark);
  line-height: 1.6;
}

.landing-feature-section-image {
  flex: 1.2;
  text-align: center;
}

/* Stats section */
.landing-stats {
  padding: 80px 20px;
  background-color: var(--background-color);
}

.landing-stats-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.landing-stats-grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 52px;
}

.landing-stat-item {
  flex: 1;
  text-align: center;
}

.landing-stat-number {
  font-size: 24px;
  font-weight: 600;
  color: var(--ml-color-darkest);
  margin-bottom: 4px;
  text-align: center;
}

.landing-stat-label {
  font-size: 14px;
  color: var(--ml-color-dark);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .landing-hero {
    padding: 60px 20px;
  }

  .landing-features-grid {
    display: flex;
    flex-direction: column;
  }
  
  .landing-hero-content {
    max-width: 100%;
  }
  
  .landing-hero h1 {
    font-size: 40px;
  }
  
  .landing-hero-subtitle {
    font-size: 18px;
  }

  .landing-cta-button {
    font-size: 14px;
    width: 180px;
  }
  
  .landing-container section {
    padding: 60px 20px;
    margin-top: 60px;
  }

  .landing-faq-heading h2,
  .landing-faq-heading p {
    text-align: center;
  }
  
  .landing-pricing-card-featured {
    transform: scale(1);
  }
  
  .landing-pricing-card-featured:hover {
    transform: translateY(-5px);
  }

  .landing-pricing-grid {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 30px;
  }

  .landing-pricing-card {
    padding: 40px 30px;
    flex: unset;
    min-width: unset;
    max-width: unset;
  }

  .landing-feature-section {
    flex-direction: column !important;
    text-align: center;
    padding: 40px 20px;
    margin-top: 40px;
  }

  .landing-pricing-amount {
    margin: 0;
    margin-bottom: 14px;
  }

  .landing-final-cta p {
    font-size: 14px;
  }

  .landing-testimonial p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .landing-feature-section-content h3 {
    font-size: 22px;
  }

  .landing-stat-label {
    font-size: 12px;
  }
  
  .landing-testimonial-author span {
    font-size: 14px;
  }

  .landing-feature-section-content {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .landing-feature-section-image {
    flex: 1;
  }

  .landing-faq-two-column {
    flex-direction: column;
    gap: 30px;
  }

  .landing-image-modal-close {
    display: none;
  }
  
  .landing-faq-heading, 
  .landing-faq-questions {
    flex: 0 0 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .landing-container h1 {
    font-size: 36px;
  }
  
  .landing-container h2 {
    font-size: 24px;
  }

  p.landing-hero-subtitle {
    font-size: 16px;
  }
  
  .landing-hero-cta {
    flex-direction: row;
    align-items: center;
  }
  
  .landing-container section {
    padding: 50px 20px;
  }
  
  .landing-video-popup-close {
    top: -30px;
    right: 0;
  }

  .landing-stats-grid {
    gap: 20px;
  }

  .landing-stat-number {
    font-size: 18px;
  }

  .landing-video-popup-content {
    width: 100%;
    max-width: 100%;
  }
  
  .landing-image-modal-content {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .landing-faq-grid {
    gap: 10px;
  }
  
  .landing-faq {
    padding: 60px 20px;
  }
}

@media (max-width: 576px) {
  .landing-container h1 {
    font-size: 32px;
  }
  
  .landing-container h2 {
    font-size: 20px;
  }
  
  .landing-pricing-amount {
    font-size: 18px;
  }
  
  .landing-pricing-number {
    font-size: 36px;
  }
  
  .landing-container section {
    padding: 40px 20px;
  }

  .landing-video-popup-overlay,
  .landing-image-modal-overlay {
    padding: 0;
  }
  
  .landing-video-popup-content,
  .landing-image-modal-content {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
  .landing-faq {
    padding: 40px 15px;
  }
  
  .landing-faq-question h4 {
    font-size: 15px;
  }
  
  .landing-faq-heading p {
    font-size: 14px;
  }
}
