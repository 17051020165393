.batch-plan-editor-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.batch-plan-editor {
    background: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.batch-plan-editor h2 {
    margin: 0 0 16px 0;
    font-size: 20px;
    color: var(--text-color);
}

.batch-plan-editor-input {
    position: relative;
    margin-bottom: 24px;
}

.batch-plan-editor-input input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
    color: var(--text-color);
    box-sizing: border-box;
}

.batch-plan-editor-input input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.batch-plan-editor-unit {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--ml-color-dark);
    font-size: 14px;
}

.batch-plan-editor-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.batch-plan-editor-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.batch-plan-editor-button.keep {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.batch-plan-editor-button.keep:hover {
    background-color: var(--background-color-hover);
}

.batch-plan-editor-button.reset {
    background-color: var(--primary-color);
    color: white;
}

.batch-plan-editor-button.reset:hover {
    filter: brightness(0.95);
}

@media (max-width: 480px) {
    .batch-plan-editor {
        padding: 20px;
        width: calc(100% - 32px);
        margin: 16px;
    }

    .batch-plan-editor-buttons {
        flex-direction: column-reverse;
    }

    .batch-plan-editor-button {
        width: 100%;
        padding: 12px;
        text-align: center;
    }
} 