.batch-stage-selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-family: var(--font-family);
}

.batch-desktop-split-section.batch-desktop-split-section-backgrounds .batch-stage-selector {
    width: 100%;
}

.batch-stage-selector-label {
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 4px;
}

.batch-stage-selector-current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: white;
    cursor: pointer;
    min-width: 120px;
    transition: all 0.2s ease;
    font-size: 14px;
    width: 100%;
}

.batch-page-container-mobile .batch-stage-selector-current {
    width: unset;
}

.batch-stage-selector-current:hover {
    border-color: var(--primary-color);
}

.batch-stage-selector-current.loading {
    opacity: 0.7;
    cursor: not-allowed;
}

.batch-stage-selector-arrow {
    font-size: 10px;
    color: var(--text-color-light);
    margin-left: 8px;
}

.batch-stage-selector-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.batch-stage-selector-option {
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 14px;
}

.batch-stage-selector-option:hover {
    filter: brightness(0.95);
}

.batch-stage-selector-option.selected {
    background-color: var(--primary-color-light);
    color: var(--primary-color);
}

.batch-stage-selector-error {
    position: absolute;
    top: 100%;
    left: 0;
    color: var(--error-color);
    font-size: 12px;
    margin-top: 4px;
    white-space: nowrap;
}

.batch-stage-selector-cure-time {
    font-size: 14px;
    color: var(--text-color-light);
    white-space: nowrap;
}

@media (max-width: 768px) {
    .batch-stage-selector {
        width: auto;
    }
    
    .batch-stage-selector-current {
        min-width: 100px;
    }
} 