.batch-timeline {
  margin-bottom: 20px;
}

.batch-timeline h2 {
  margin-bottom: 12px;
}

.batch-timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 36px;
  box-sizing: border-box;
}

.batch-timeline-entry {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.batch-timeline-bullet {
  position: absolute;
  left: -36px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.batch-timeline-icon {
  font-size: 18px;
}

.batch-timeline-bullet-past {
  color: var(--ml-color-val-green);
}

/* Stage-specific bullet colors for current stage */
.batch-timeline-bullet-new {
  color: var(--ml-color-stage-new);
}

.batch-timeline-bullet-pouring {
  color: var(--ml-color-stage-pouring);
}

.batch-timeline-bullet-curing {
  color: var(--ml-color-stage-curing);
}

.batch-timeline-bullet-testing {
  color: var(--ml-color-stage-testing);
}

.batch-timeline-bullet-done {
  color: var(--ml-color-stage-done);
}

.batch-timeline-bullet-current {
  color: var(--ml-color-dark);
}

.batch-timeline-line {
  position: absolute;
  left: -26px;
  top: 20px;
  bottom: -20px;
  width: 1px;
  background-color: #e0e0e0;
  z-index: 1;
}

.batch-timeline-date {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.batch-timeline-stage {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
  color: var(--ml-color-dark);
}

.batch-timeline-duration {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
  font-style: italic;
}

/* View All button */
.batch-timeline-view-all {
  background-color: transparent;
  border: 1px solid var(--ml-color-borders);
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 4px;
  cursor: pointer;
  font-size: 13px;
  color: var(--ml-color-accent);
  transition: all 0.2s ease;
}

.batch-timeline-view-all:hover {
  background-color: var(--ml-color-background-light);
  border-color: var(--ml-color-accent);
}

/* Modal styles */
.batch-timeline-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.batch-timeline-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.batch-timeline-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--ml-color-borders);
}

.batch-timeline-modal-header h2 {
  margin: 0;
  font-size: 18px;
}

.batch-timeline-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.batch-timeline-modal-close:hover {
  color: var(--ml-color-accent);
}

.batch-timeline-modal-content {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(80vh - 70px);
}

.batch-timeline-modal-container {
  padding-left: 36px;
  max-height: none;
  overflow-y: visible;
  margin-right: 0;
}

/* For when the timeline has many entries */
@media (min-width: 768px) {
  .batch-timeline-container {
    max-height: 300px;
    overflow-y: auto;
    margin-right: 12px;
  }
  
  .batch-timeline-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .batch-timeline-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .batch-timeline-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  .batch-timeline-container::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .batch-timeline-container {
    padding-left: 24px;
  }
  
  .batch-timeline-bullet {
    left: -16px;
  }
  
  .batch-timeline-line {
    left: -10px;
  }
  
  .batch-timeline-modal {
    width: 95%;
    max-height: 90vh;
  }
  
  .batch-timeline-modal-content {
    max-height: calc(90vh - 70px);
  }
} 