.batch-table-stage-selector-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.batch-table-stage-selector-modal {
    background: white;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: modalFadeIn 0.2s ease-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.batch-table-stage-selector-header {
    padding: 16px 20px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.batch-table-stage-selector-header-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.batch-table-stage-selector-header h3 {
    margin: 0;
    font-size: 18px;
    color: #212529;
}

.batch-table-stage-selector-batch-name {
    font-size: 14px;
    color: #6c757d;
    font-weight: 500;
}

.batch-table-stage-selector-close {
    background: none;
    border: none;
    font-size: 24px;
    color: #6c757d;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.batch-table-stage-selector-close:hover {
    color: #343a40;
}

.batch-table-stage-selector-content {
    padding: 20px;
}

.batch-table-stage-selector-stages {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.batch-table-stage-selector-option {
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    text-align: left;
    background: var(--stage-bg);
    color: var(--stage-color);
    opacity: 0.8;
}

.batch-table-stage-selector-option:hover {
    opacity: 1;
}

.batch-table-stage-selector-option.selected {
    opacity: 1;
    border-color: var(--stage-color);
}

.batch-table-stage-selector-error {
    margin: 16px 0;
    padding: 12px;
    background: #f8d7da;
    border: 1px solid #f5c2c7;
    border-radius: 4px;
    color: #842029;
    font-size: 14px;
}

.batch-table-stage-selector-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #dee2e6;
}

.batch-table-stage-selector-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.batch-table-stage-selector-button.secondary {
    background: white;
    border: 1px solid #dee2e6;
    color: #6c757d;
}

.batch-table-stage-selector-button.secondary:hover {
    background: #f8f9fa;
    border-color: #c1c9d0;
}

.batch-table-stage-selector-button.primary {
    background: var(--ml-color-darkest);
    border: 1px solid var(--ml-color-darkest);
    color: white;
}

.batch-table-stage-selector-button.primary:hover {
    background: #3a3737;
}

.batch-table-stage-selector-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
} 