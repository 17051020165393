.cure-timer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cure-timer-modal {
    background: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cure-timer-modal h2 {
    margin: 0 0 16px 0;
    font-size: 20px;
    color: var(--text-color);
}

.cure-timer-modal p {
    margin: 0 0 24px 0;
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color);
}

.cure-timer-modal-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.cure-timer-modal-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.cure-timer-modal-button.keep {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

.cure-timer-modal-button.keep:hover {
    background-color: var(--background-color-hover);
}

.cure-timer-modal-button.reset {
    background-color: var(--primary-color);
    color: white;
}

.cure-timer-modal-button.reset:hover {
    filter: brightness(0.95);
}

@media (max-width: 480px) {
    .cure-timer-modal {
        padding: 20px;
        width: calc(100% - 32px);
        margin: 16px;
    }

    .cure-timer-modal-buttons {
        flex-direction: column;
    }

    .cure-timer-modal-button {
        width: 100%;
        padding: 12px;
        text-align: center;
    }
} 