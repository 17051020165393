body {
    --ml-color-stage-new: #E3F2FD;
    --ml-color-stage-pouring: #FFF3E0;
    --ml-color-stage-curing: #F3E5F5;
    --ml-color-stage-testing: #FFF9C4;
    --ml-color-stage-done: #E8F5E9;
    --ml-color-stage-new-text: #495057;
    --ml-color-stage-pouring-text: #055160;
    --ml-color-stage-curing-text: #664d03;
    --ml-color-stage-testing-text: #856404;
    --ml-color-stage-done-text: #0f5132;
    --ml-font-styles:: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --ml-font-size: 16px;
    --ml-color-darkest: #252323;
    --ml-color-dark: #70798C;
    --ml-color-lightest: #f5f1ED;
    --ml-color-lighter: #dac7bc;
    --ml-color-light: #A99985;
    --ml-color-button: #F9E7E7;
    --ml-color-card: #EBADAD;
    --ml-color-card-batches: #F4F0FA;
    --ml-color-card-candles: #E8EFE6;
    --ml-color-acc: #ffbf42;
    --ml-color-accent: #334e87;
    --ml-color-val-green: #0f860b;
    --ml-color-val-red: #860b0b;
    --ml-color-val-yellow: #ffef1585;
    --ml-color-confirmation: rgb(31, 76, 202);
    --ml-color-moonlite-black: #212121;
    --ml-color-empty-state-background: rgb(206, 206, 206);
    --ml-color-borders: rgb(228, 228, 228);
    --ml-color-border-dark: rgb(214, 214, 214);
    --ml-color-subtle-text-background: rgb(241, 244, 246);
    --ml-color-timeline-bullet: var(--ml-color-accent);
    --text-color: var(--ml-color-darkest);
    --background-color: #ffffff;
    --background-color-hover: #f5f5f5;
    --border-color: var(--ml-color-borders);
    --primary-color: var(--ml-color-accent);
} 