.inventory-settings-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.inventory-settings-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.inventory-settings-modal-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--ml-color-darkest);
}

.inventory-settings-modal-section {
    margin-bottom: 20px;
}

.inventory-settings-modal-section-title,
.inventory-settings-modal-section-subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
    color: var(--ml-color-dark);
}

.inventory-settings-modal-section-subtitle {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 8px;
}

.inventory-settings-modal-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inventory-settings-modal-option {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid var(--ml-color-borders);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.inventory-settings-modal-option:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.inventory-settings-modal-option.selected {
    border-color: var(--ml-color-primary);
    background-color: rgba(var(--ml-color-primary-rgb), 0.05);
}

.inventory-settings-modal-option-radio {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    accent-color: var(--ml-color-primary);
}

.inventory-settings-modal-option-label {
    font-size: 14px;
    color: var(--ml-color-dark);
}

.inventory-settings-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.inventory-settings-modal-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.inventory-settings-modal-button.cancel {
    background-color: transparent;
    border: 1px solid var(--ml-color-borders);
    color: var(--ml-color-dark);
}

.inventory-settings-modal-button.cancel:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.inventory-settings-modal-button.save {
    background-color: var(--ml-color-darkest);
    border: 1px solid var(--ml-color-darkest);
    color: white;
}

.inventory-settings-modal-button.save:hover {
    background-color: var(--ml-color-dark);
}

/* Mobile specific styles */
@media (max-width: 768px) {
    .inventory-settings-modal-content {
        padding: 20px;
    }
    
    .inventory-settings-modal-option-label {
        font-size: 16px;
    }
    
    .inventory-settings-modal-button {
        font-size: 16px;
        padding: 10px 18px;
    }
} 