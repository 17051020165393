.create-recipe-limits {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 80px;
}

.create-recipe-limits-title {
    font-size: 18px;
    text-align: center;
    max-width: 600px;
}

.create-recipe-limits-title span {
    font-weight: 600;
}

.create-recipe-limits-subtitle {
    font-size: 14px;
    text-align: center;
    max-width: 600px;
}

.create-recipe-limits-subtitle span {
    font-weight: 600;
    text-transform: capitalize;
}

.create-recipe-limits-button {
    font-size: 14px;
    background-color: var(--ml-color-darkest);
    padding: 12px 32px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 6px;
}

.create-recipe-text-button {
    text-decoration: underline;
    color: grey;
    cursor: pointer;
    font-size: 14px;
}