.batch-rename-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.batch-rename-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.batch-rename-modal-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    color: var(--ml-color-dark);
}

.batch-rename-modal-input-container {
    margin-bottom: 24px;
}

.batch-rename-modal-input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--ml-color-borders);
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.batch-rename-modal-input:focus {
    outline: none;
    border-color: var(--ml-color-primary);
    box-shadow: 0 0 0 2px rgba(var(--ml-color-primary-rgb), 0.2);
}

.batch-rename-modal-error {
    color: #d32f2f;
    font-size: 12px;
    margin-top: 4px;
}

.batch-rename-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.batch-rename-modal-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.batch-rename-modal-button.cancel {
    background-color: transparent;
    border: 1px solid var(--ml-color-borders);
    color: var(--ml-color-dark);
}

.batch-rename-modal-button.cancel:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.batch-rename-modal-button.save {
    background-color: var(--ml-color-darkest);
    border: 1px solid var(--ml-color-darkest);
    color: white;
}