.recipe-table-collections {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.recipe-table-collection-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}

.recipe-table-collection-pill {
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: var(--ml-color-darkest);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.2s ease;
    border: 2px solid transparent;
}

.recipe-table-collection-pill:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.recipe-table-collection-pill.active {
    border: 2px solid var(--ml-color-darkest);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recipe-table-filter-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    color: #666;
    transition: background-color 0.2s ease;
}

.recipe-table-filter-button:hover {
    background-color: #eee;
}

.recipe-table-selected-collections {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

/* Remove the title from the batch table header */
.batch-table-header h2 {
    display: none;
}

/* Styles for collection pills in the table */
.recipe-table-cell-collection {
    display: inline-flex;
    gap: 4px;
    flex-wrap: wrap;
}

.recipe-table-cell-collection-pill {
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    color: var(--ml-color-darkest);
    white-space: nowrap;
}

/* Manage button styles */
.recipe-table-manage-button {
    background: none;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    color: var(--ml-color-dark);
    transition: all 0.2s ease;
}

.recipe-table-manage-button:hover {
    background-color: var(--ml-color-lightest);
    color: var(--ml-color-darkest);
}

/* Manage column styles */
.recipe-table-manage-cell {
    text-align: right;
}

.recipe-table-manage-cell .recipe-table-manage-button {
    margin-right: 8px;
}

.recipe-table-manage-header {
    text-align: right;
    padding-right: 16px !important;
}

.recipe-table-manage-header .table-header-content {
    justify-content: flex-end;
    padding-right: 8px;
}

/* Recipe Menu styles */
.recipe-menu {
    position: fixed;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 180px;
    z-index: 1000;
    overflow: hidden;
}

.recipe-menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--ml-color-darkest);
    font-size: 14px;
    text-align: left;
    transition: background-color 0.2s ease;
}

.recipe-menu-item:hover {
    background-color: var(--ml-color-lightest);
}

.recipe-menu-item-delete {
    color: var(--ml-color-error);
}

.recipe-menu-item-delete:hover {
    background-color: var(--ml-color-error-light);
} 

.recipes-table-plan-allowance-container {
    display: flex;
    flex-direction: row-reverse;
    font-size: 12px;
    color: var(--ml-color-light);
    font-weight: 500;
    margin: 8px 0;
    margin-top: 0;
}

.recipes-table-plan-allowance-warning-container {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 14px 0;
    background-color: rgba(255, 255, 0, 0.212);
}

.recipes-table-plan-allowance-warning-container p {
    margin: 0;
}

.recipes-table-plan-allowance-warning-container span {
    font-weight: 600;
    text-decoration: underline;
    color:var(--ml-color-accent);
    cursor: pointer;
}