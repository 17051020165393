.batch-table-container {
    padding: 20px 16px;
    background: white;
    border-radius: 8px;
}

.batch-table-container .general-category-page-header h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
}

.batch-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.batch-table-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.batch-table-actions {
    display: flex;
    gap: 12px;
    align-items: center;
}

.batch-table-search {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    width: 250px;
    transition: border-color 0.2s;
    font-family: var(--ml-font-styles);
    position: relative;
}

.batch-table-search-container {
    position: relative;
}

.batch-table-search-clear {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
}

.batch-table-search-clear:hover {
    color: #333;
}

.batch-table-search:focus {
    outline: none;
    border-color: #007bff;
}

.batch-table-new-button {
    padding: 8px 16px;
    background-color: var(--ml-color-darkest);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.batch-table-new-button:hover {
    background-color: #3a3737;
}

.batch-table-filters {
}

.batch-table-stage-filters {
    display: flex;
    gap: 8px;
    align-items: center;
}

.filters-label {
    color: #666;
    font-size: 14px;
}

.batch-table-stage-filter {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.batch-table-stage-filter .batch-count {
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 1px 6px;
    min-width: 20px;
    text-align: center;
}

.batch-table-stage-filter span {
    font-size: 12px;
    margin-left: 12px;
}

/* Stage-specific filter styles */
.batch-table-stage-filter[data-stage="NEW"] {
    background: var(--ml-color-stage-new);
    opacity: 0.6;
}

.batch-table-stage-filter[data-stage="NEW"].active {
    opacity: 1;
}

.batch-table-stage-filter[data-stage="POURING"] {
    background: var(--ml-color-stage-pouring);
    opacity: 0.6;
}

.batch-table-stage-filter[data-stage="POURING"].active {
    opacity: 1;
}

.batch-table-stage-filter[data-stage="CURING"] {
    background: var(--ml-color-stage-curing);
    opacity: 0.6;
}

.batch-table-stage-filter[data-stage="CURING"].active {
    opacity: 1;
}

.batch-table-stage-filter[data-stage="TESTING"] {
    background: var(--ml-color-stage-testing);
    opacity: 0.6;
}

.batch-table-stage-filter[data-stage="TESTING"].active {
    opacity: 1;
}

.batch-table-stage-filter[data-stage="DONE"] {
    background: var(--ml-color-stage-done);
    opacity: 0.6;
}

.batch-table-stage-filter[data-stage="DONE"].active {
    opacity: 1;
}

.batch-table-wrapper {
    overflow-x: auto;
}

.batch-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 14px;
}

.batch-table tr {
    cursor: default;
}

.table-header-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}

.table-header-content.active {
    color: var(--primary-color);
}

.sort-icon {
    font-size: 18px;
    opacity: 0.5;
    transition: opacity 0.2s, transform 0.2s;
}

.sort-icon.active {
    opacity: 1;
    color: var(--primary-color);
}

.table-header-content:hover .sort-icon {
    opacity: 0.8;
}

.batch-table th {
    background: #f8f9fa;
    padding: 12px 16px;
    text-align: left;
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
    transition: background-color 0.2s;
}

.batch-table th:hover {
    background: #e9ecef;
}

.batch-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
}

.batch-table tbody tr {
    cursor: default;
    transition: background-color 0.2s;
}

.batch-table tbody tr:hover {
    background-color: #f8f9fa;
}

.stage-column {
    display: flex;
    align-items: center;
    gap: 8px;
}

.batch-stage-tag {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.batch-stage-new {
    background: var(--ml-color-stage-new);
    color: var(--ml-color-stage-new-text);
}

.batch-stage-pouring {
    background: var(--ml-color-stage-pouring);
    color: var(--ml-color-stage-pouring-text);
}

.batch-stage-curing {
    background: var(--ml-color-stage-curing);
    color: var(--ml-color-stage-curing-text);
}

.batch-stage-testing {
    background: var(--ml-color-stage-testing);
    color: var(--ml-color-stage-testing-text);
}

.batch-stage-done {
    background: var(--ml-color-stage-done);
    color: var(--ml-color-stage-done-text);
}

.stage-info {
    font-size: 12px;
    color: #6c757d;
}

.recipe-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.batch-table-link {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

.batch-table-link:hover {
    text-decoration: underline;
}

.recipe-id {
    font-size: 12px;
    color: #6c757d;
}

.batch-table-error-banner {
    margin: 20px 0;
    padding: 12px;
    background: #f8d7da;
    border: 1px solid #f5c2c7;
    border-radius: 4px;
    color: #842029;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.batch-table-retry {
    padding: 6px 12px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.batch-table-retry:hover {
    background: #bb2d3b;
}

.batch-table-empty {
    text-align: center;
    padding: 40px 20px;
    color: #6c757d;
}

.batch-table-empty-icon {
    font-size: 48px;
    margin-bottom: 16px;
    color: #adb5bd;
}

.batch-table-empty h3 {
    margin: 0 0 8px;
    color: #495057;
}

.batch-table-empty p {
    margin: 0 0 16px;
}

.batch-table-clear-filters {
    padding: 8px 16px;
    background: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.batch-table-clear-filters:hover {
    background: #5c636a;
}

.batch-table-loading {
    text-align: center;
    padding: 40px;
    color: #6c757d;
}

.batch-table-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
    padding: 16px;
    border-top: 1px solid #dee2e6;
}

.batch-table-pagination-button {
    padding: 8px 16px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background: white;
    color: var(--primary-color);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.batch-table-pagination-button:hover:not(:disabled) {
    background: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
}

.batch-table-pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.batch-table-pagination-info {
    color: #6c757d;
    font-size: 14px;
}

.recipe-not-found {
    color: #999;
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .batch-table-search {
        font-size: 16px;
    }
}

.active-filter-count {
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    color: #495057;
    font-weight: 600;
}

.created-date {
    color: #666;
    white-space: nowrap;
    text-align: left;
    font-size: 14px;
}