.collection-filter-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.collection-filter-modal {
    background: white;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.collection-filter-modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collection-filter-modal-header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.collection-filter-modal-close {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;
}

.collection-filter-modal-close:hover {
    background-color: #f5f5f5;
}

.collection-filter-modal-content {
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.collection-filter-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.collection-filter-item:hover {
    background-color: #f5f5f5;
}

.collection-filter-item.active {
    background-color: #f0f0f0;
}

.collection-filter-color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.collection-filter-name {
    font-size: 14px;
    color: #333;
}

.collection-filter-empty {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
} 