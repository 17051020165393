.popup-question-outer {
    position: fixed;
    right: 12px;
    bottom: 12px;
    z-index: 100;
    display: default;
}

.popup-question-inner {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border: 2px solid rgb(235, 235, 235);
    background-color: rgb(255, 255, 255);
    padding: 14px 12px;
    gap: 10px;
    border-radius: 8px;
    z-index: 200;
}

.popup-question-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 6px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.popup-question-text {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    color: var(--ml-color-darkest);
    font-weight: 400;
}

.popup-question-text span {
    color: var(--ml-color-accent);
    font-weight: 500;
    cursor: pointer;
}

.popup-question-text span:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1200px) {
    .popup-question-outer {
        display: none;
    }
}